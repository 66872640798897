import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AgreemenService } from '../../services/agreemen.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-modal-basic-pdf',
  templateUrl: './modal-basic-pdf.component.html',
  styleUrls: ['./modal-basic-pdf.component.css', '../../../assets/icon/icofont/css/icofont.scss']
})
export class ModalBasicPdfComponent implements OnInit {
  @Input() dialogClass: string;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() blob :Blob;
  @Input() filename :string;
 url:SafeResourceUrl;
  public visible = false;
  public visibleAnimate = false;
  private html: any;
  @Input() enablePrint: boolean;
  private src: string;
  private defultFileName: string;
  constructor(private spinnerService: Ng4LoadingSpinnerService  ,private sanitizer: DomSanitizer,private _AgreemenService:AgreemenService) { }

  ngOnInit() {
    
  //   this._AgreemenService.GetAgmReport(249).subscribe(res => {
  //     var blob = new Blob([res], { type: 'application/pdf' });
  //     this.src = URL.createObjectURL(blob);
  //   let url=`${this.src}#zoom=130&toolbar=0`;
  //   let html = ` <embed src="${url}#zoom=130&toolbar=0" style="width:100%; height:460px;" />`
  //   this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  //   this.html = this.sanitizer.bypassSecurityTrustHtml(html);
  // });

  }

  public show(): void {
   
    this.src = URL.createObjectURL(this.blob);
    let url=`${this.src}#zoom=130&toolbar=0`;
    let html = ` <embed src="${url}#zoom=130&toolbar=0" style="width:100%; height:460px;" />`
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.html = this.sanitizer.bypassSecurityTrustHtml(html);
    this.visible = true;
    this.spinnerService.hide();
    
    setTimeout(() => this.visibleAnimate = true, 100);
    
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }
  Download(){
    var today = Date.now();
    var date = today;
    this.defultFileName= 'report_'+ date+'.pdf';
    const element = document.createElement('a');
      element.href =this.src;
       element.download = this.filename? this.filename:this.defultFileName;
      document.body.appendChild(element);
      element.click();
  }
  public onContainerClicked(event: MouseEvent): void {
    // if ((<HTMLElement>event.target).classList.contains('modal')) {
    //   this.hide();
    // }
  }

}
