import { AuthenService } from './../../services/authen.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from "@angular/router";
import { Observable } from "rxjs/Rx";
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../services/permission.service';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    currentUser: any;
    currentLanguage: string;
    constructor(private _router: Router,
        public _authService: AuthenService,
        public translate: TranslateService,
        public _permissionService: PermissionService) {
        this.setLanguage();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));


        let URlRedirect = state.url;
        if (state.url) {
            if (state.url.indexOf('?') > -1) {
                URlRedirect = state.url.split('?')[0];
            }
        }
        console.log("URlRedirect", URlRedirect);
        if (this.currentUser) {
            // if()
            // {
            return this._authService.verify().pipe(
                tap((response: boolean) => {
                    if (!this.checkCanAccessUrl(URlRedirect))
                        this._router.navigate(['/not-authorized']);
                }),
                catchError(err => {
                    console.log('Handling error locally and rethrowing it...', err);
                    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                    return of(false);
                })
            );
            // console.log(this._authService.verify());

            // return this._authService.verify().map(
            //             data => {
            //                 if (data) {
            //                // if (data.Data) {
            //                     // logged in so return true
            //                     if(this.checkCanAccessUrl(URlRedirect))
            //                     {
            //                         return true;
            //                     }
            //                     else
            //                     {
            //                         this._router.navigate(['/not-authorized']);

            //                     }
            //                 }
            //             // }
            //             // else{
            //             //     alert(data.Msg);
            //             // }
            //                 // error when verify so redirect to login page with the return url
            //                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            //                 return false;
            //             },
            //             error => {
            //                 // error when verify so redirect to login page with the return url
            //                 this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            //                 return false;
            //             });
            // }
            //     else if (this.checkCanAccessUrl(URlRedirect)) {
            //     return true;
            // } else {
            //     this._router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            //     return false;
            // }
            //return true;
        }
        else {
            this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        // if (this.currentUser) {
        //     // if (URlRedirect == "/user/profile"
        //     //     || URlRedirect == "/user/loginhistory"
        //     //     || URlRedirect == '/report/list') 
        //     //     {
        //     return true;
        //     // return this._authService.verify().map(
        //     //     data => {
        //     //         if (data) {
        //     //             // logged in so return true
        //     //             return true;
        //     //         }
        //     //         // error when verify so redirect to login page with the return url
        //     //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //     //         return false;
        //     //     },
        //     //     error => {
        //     //         // error when verify so redirect to login page with the return url
        //     //         this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //     //         return false;
        //     //     });
        //     // }
        //     // else if (this.checkCanAccessUrl(URlRedirect)) {
        //     //     return true;
        //     // } else {
        //     //     this._router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        //     //     return false;
        //     // }

        // } else {
        //     this._router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        //     return false;
        // }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return true;
    }

    setLanguage() {
        this.currentLanguage = localStorage.getItem('Language');
        if (this.currentLanguage !== null && this.currentLanguage !== '') {
            this.translate.use(this.currentLanguage);
        } else {
            this.translate.use('en');
        }
    }

    checkCanAccessUrl = (url: string): boolean => {
        return this._permissionService.CheckCanAccessURL(url);
    }
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
           // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}