import { AgmAttachment } from './../model/setting.model';
import { ReturnApi } from './../model/Common.model';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalService } from './modal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AgmSelectedTab, AgreementList_Search, AgreementList, AgmHeader, UpdateStatusAgreement, AgmAprroval, ApprovAgmModel } from '../model/agreement.model';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { POBookingDetail, paramDPReport2, DisplayRequest } from '../model/booking.model';


// const httpOptions = {
//     headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
//       'Access-Control-Allow-Origin': '*',
//       'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
//       'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
//     })
//   };
const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};
  const API_Url = environment;
let BASEURL = '';
@Injectable()
export class SupplierService {
  
  
  
 
  

  


constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;

 } 
 CheckTimeBooking(search) {
  return this.http.post<any>(BASEURL + 'api/Supplier/CheckTimeBooking/',search, httpOptions) ;
}
 GetBookingDailyGantt(search) {
  return this.http.post<any>(BASEURL + 'api/Supplier/GetBookingDailyGantt/',search, httpOptions) ;
}
 CheckDCApproverByUserID(value) {
  return this.http.post<any>(BASEURL + 'api/Supplier/CheckDCApproverByUserID/',value, httpOptions) ;
}
 getpobookingbybookingid(id: any) {
  return this.http.get<any>(BASEURL + 'api/Supplier/getpobookingbybookingid/'+id, httpOptions).pipe(tap(response => { }));
}

RejectBooking(model: any) {
  return this.http.post<any>(BASEURL + 'api/Supplier/RejectBooking/', model);
}
  //Get_PO4Supplier(param: any) {
    //return this.http.post<any>(BASEURL + 'api/Supplier/SupplierGetpo/', param);
  //}
  ApproveBooking(model: POBookingDetail) {
    return this.http.post<any>(BASEURL + 'api/Supplier/ApproveBooking/', model);
  }
  saveAllDC(H: any) {
    return this.http.post<any>(BASEURL + 'api/Supplier/saveAllDC/', H);
  }
  saveOnceDC(H: any) {
    return this.http.post<any>(BASEURL + 'api/Supplier/saveOnceDC/', H);
  }
  saveApprover(model: any) {
    return this.http.post<any>(BASEURL + 'api/Supplier/saveApprover/', model);
  }
  saveGate(model: any) {
    return this.http.post<any>(BASEURL + 'api/Supplier/saveGate/', model);
  }
  getdcapproverForSetting(plantCode: any) {
    return this.http.get<any>(BASEURL + 'api/Supplier/getdcapproverForSetting/'+plantCode, httpOptions).pipe(tap(response => { }));
  }
  
  Get_PlantDC() {
    return this.http.get<any>(BASEURL + 'api/Supplier/Get_PlantDC/', httpOptions).pipe(tap(response => { }));
  }
  GetUserforSupplierList(Param:string): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/Supplier/getuserforsupplier/'+ Param, httpOptions).pipe(tap(response => { }));
  }
  GetBookingSearch(SearchParam?: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/Supplier/getBookingList', SearchParam, httpOptions).pipe(tap(response => { }));
    // return this.http.post<any>(BASEURL + 'api/agreement' + '/getagmlist',  httpOptions).pipe(tap(response => { }));
  } 
  getpobooking(param: any) {
    return this.http.post<any>(BASEURL + 'api/Supplier/getpobooking/', param);
  }
  BookingManage(model: POBookingDetail):Observable<ReturnApi> {
    return this.http.post<any>(BASEURL + 'api/Supplier/BookingManage/', model);
  }
  getAllBookingDetailByid(Bookingid:number): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/Supplier/getAllBookingDetailByid/'+ Bookingid, httpOptions).pipe(tap(response => { }));
  }
  getdcholidayforset(plantcode: any) {
    return this.http.get<any>(BASEURL + 'api/Supplier/getdcholidayforset/'+plantcode, httpOptions).pipe(tap(response => { }));
  }
  getPDFtoShow(data: any) {
    return this.http.post(BASEURL + 'api/Supplier' + '/ShowPDF/', data, { responseType: 'blob' })
  }
 

  GetBookingReportExcel(data: any): Observable<any> {
    return this.http.post(BASEURL + 'api/Supplier/GetBookingReportExcel/', data, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',

      }),
      responseType: 'arraybuffer'
    }).pipe(tap((x) => {
      console.log("x", x);
    }),
      tap((y) => {
        console.log("y", y);
        catchError(this.handleError<any>("GetBookingReportExcel"))
      }),
    );
  }
  addlogprintdphistory(data: paramDPReport2): Observable<any>{
    return this.http.post(BASEURL + 'api/Supplier' + '/addlogprintdphistory/', data)
  }
  DeleteBookingByBookingid(Bookingid:number): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/Supplier/DeleteBookingByBookingid/'+ Bookingid, httpOptions).pipe(tap(response => { }));
  }
  getSI_SCGN_EPUR_DISPLAY_DN_REQ(param: DisplayRequest): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/Scg/SI_SCGN_EPUR_DISPLAY_DN_REQRequest/', param);
  }

 
  pdf(value:any) {
    return this.http.post(BASEURL + 'api/scg' + '/PDFPhase2', value, { headers: httpOptions.headers, responseType: 'blob' });
  }

  pdfv2(value:any) {
    return this.http.post(BASEURL + 'api/scg' + '/PDFV2', value, { headers: httpOptions.headers, responseType: 'blob' });
  }

  getcountapprovebooking(data):Observable<any> {
    return this.http.post<any>(BASEURL + 'api/Supplier/getcountapprovebooking/',data,  httpOptions).pipe(tap(response => { }));
   }
   
  /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${error.message}`);

          // Let the app keep running by returning an empty result.
          return of(result as T);
      };
  }

  /** Log a ScgCompanyService message with the MessageService */
  private log(message: string) {
      console.log(message);
      // this.messageService.add('ScgCompanyService: ' + message);
  }
}

