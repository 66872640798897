import { AuthConfig } from "angular-oauth2-oidc";

export const environment = {
    production: true,
    R3_GI: 'https://cbmpi.scg.com/RESTAdapter/R3GIGRRefDN',
    S4_GI: 'https://cbmpi.scg.com/RESTAdapter/GIGRRefDN',
    R3_GI_Reverse: 'https://cbmpi.scg.com/RESTAdapter/R3REVGIGRRefDN',
    S4_GI_Reverse: 'https://cbmpi.scg.com/RESTAdapter/REVGIGRRefDN',
    LOG: 'https://localhost:44353/api/SCG',
    PDF: 'https://localhost:44353/api/PdfCreator/PDF',
    DNGetList: 'https://cbmpi.scg.com/RESTAdapter/DNGetList',
    R3DNGetList: 'https://cbmpi.scg.com/RESTAdapter/R3DNGetList',
    DNGetGroup: 'https://cbmpi.scg.com/RESTAdapter/DNGetGroup',
    ShowLogInDev: false,
    redirectUrl: 'https://scgdpurchasing.scg.com',
    clientId:'4ced3fea-44ec-4980-bb62-4e195365ad86',
    tenantId:'5db8bf0e-8592-4ed0-82b2-a6d4d77933d4',
};

export const ADFS = {
    production: false,
    issuer: 'https://access.scg.co.th/adfs/ls/',
    resource: 'https://scgdpurchasing.scg.com',
    redirectUri: 'https://scgdpurchasing.scg.com/login',
    loginUrl: 'https://access.scg.co.th/adfs/oauth2/authorize',
    tokenEndpoint: 'https://access.scg.co.th/adfs/oauth2/token',
    logoutUrl: 'https://scgdpurchasing.scg.com',
    clientId: 'd18b5422-89c7-491b-a1e1-a168d9e6c172',
    responseType: 'code',
    scope: '',
    oidc: false,
};

export const authConfig: AuthConfig = {
  issuer: 'https://access.scg.co.th/adfs',
  loginUrl: 'https://access.scg.co.th/adfs/oauth2/authorize/',
  logoutUrl: 'https://access.scg.co.th/adfs/oauth2/logout',
  redirectUri: 'https://scgdpurchasing.scg.com/login',
  clientId: 'd18b5422-89c7-491b-a1e1-a168d9e6c172',
  scope: 'profile',
  responseType: 'id_token'
}
