import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { InspectionDetailList, InspectionSearch, GetInvoiceNo4IQCResult, InspectionTypeSearch, GetInspectionResultResult, InspectionH, SamplingSearch, InspectionD, InspectionEdit, InspectionHeaderList, GetSamplingDetail4SQEResult, InspectionItem4SQESearch, SQEInspectionH, GetInvoiceNo4SQEResult } from "../model/inspection.model";
import { ReturnApi } from "../model/Common.model";
import { InventorySearch, InventoryDetailList, InventoryDetail, StockBalance } from "../model/inventory";
import { RptMaterialSearch, RptMaterialReceiveSummary, RptMaterialReceiveDetail, RptIQCInspectionDetailResult, RptProductionResultDaily, InventoryTransactionForWinspeed } from "../model/report";


const API_URL = environment.R3_GI_Reverse;
const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
        }
    )
};
let BASEURL = '';

@Injectable()
export class ReportService {
    private Url = '';  // URL to web api
    currentUser: any;

    constructor(
        private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        console.log(this.currentUser);
        this.Url = API_URL + '/' + ("1") + '/Report';
        BASEURL = baseUrl;

    }

    getMaterialSummaryList(search: RptMaterialSearch): Observable<RptMaterialReceiveSummary[]> {
        return this.http.post<RptMaterialReceiveSummary[]>(this.Url + "/RptMaterialReceiveSummary", search, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }
    getMaterialDetailList(search: RptMaterialSearch): Observable<RptMaterialReceiveDetail[]> {
        return this.http.post<RptMaterialReceiveDetail[]>(this.Url + "/RptMaterialReceiveDetail", search, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }
    getIQCList(search: RptMaterialSearch): Observable<RptIQCInspectionDetailResult[]> {
        return this.http.post<RptIQCInspectionDetailResult[]>(this.Url + "/RptIQCInspectionDetailResult", search, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }

    getSQEList(search: RptMaterialSearch): Observable<RptIQCInspectionDetailResult[]> {
        return this.http.post<RptIQCInspectionDetailResult[]>(this.Url + "/RptSQEInspectionDetailResult", search, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }
    GetInventoryTransactionForWinspeed(search: RptMaterialSearch): Observable<InventoryTransactionForWinspeed[]> {
        return this.http.post<InventoryTransactionForWinspeed[]>(this.Url + "/GetInventoryTransactionForWinspeed", search, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }

    RptWinspeed(data: Array<InventoryTransactionForWinspeed>, type: number): Observable<any> {

        return this.http.post(this.Url + '/RptWinspeed2/' + type, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            }),
            responseType: 'arraybuffer'
        }).pipe(tap((x) => {
        }),
            catchError(this.handleError<InventorySearch>('SearchWorkPermit'))
        );
    }

    getFGInspectionList(search: RptMaterialSearch): Observable<RptIQCInspectionDetailResult[]> {
        return this.http.post<RptIQCInspectionDetailResult[]>(this.Url + "/RptLATInspectionDetailResult", search, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }
    getProductionResultList(search: RptMaterialSearch): Observable<RptProductionResultDaily[]> {
        return this.http.post<RptProductionResultDaily[]>(this.Url + "/RptProductionResultDaily", search, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }

    RptMaterialReceiveSummaryExport(search: RptMaterialSearch, type: number): Observable<any> {

        return this.http.post(this.Url + '/RptMaterialReceiveSummaryExport/' + type, search, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            }),
            responseType: 'arraybuffer'
        }).pipe(tap((x) => {
        }),
            catchError(this.handleError<RptMaterialSearch>('SearchWorkPermit'))
        );
    }

    RptMaterialReceiveDetailExport(search: RptMaterialSearch, type: number): Observable<any> {

        return this.http.post(this.Url + '/RptMaterialReceiveDetailExport/' + type, search, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            }),
            responseType: 'arraybuffer'
        }).pipe(tap((x) => {
        }),
            catchError(this.handleError<RptMaterialSearch>('SearchWorkPermit'))
        );
    }

    RptIQCInspectionDetailResultExport(search: RptMaterialSearch, type: number): Observable<any> {

        return this.http.post(this.Url + '/RptIQCInspectionDetailResultExport/' + type, search, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            }),
            responseType: 'arraybuffer'
        }).pipe(tap((x) => {
        }),
            catchError(this.handleError<RptMaterialSearch>('SearchWorkPermit'))
        );
    }

    RptSQEInspectionDetailResultExport(search: RptMaterialSearch, type: number): Observable<any> {

        return this.http.post(this.Url + '/RptSQEInspectionDetailResultExport/' + type, search, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            }),
            responseType: 'arraybuffer'
        }).pipe(tap((x) => {
        }),
            catchError(this.handleError<RptMaterialSearch>('SearchWorkPermit'))
        );
    }

    RptLATInspectionDetailResultExport(search: RptMaterialSearch, type: number): Observable<any> {

        return this.http.post(this.Url + '/RptLATInspectionDetailResultExport/' + type, search, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            }),
            responseType: 'arraybuffer'
        }).pipe(tap((x) => {
        }),
            catchError(this.handleError<RptMaterialSearch>('SearchWorkPermit'))
        );
    }

    RptProductionResultDailyExport(search: RptMaterialSearch, type: number): Observable<any> {

        return this.http.post(this.Url + '/RptProductionResultDailyExport/' + type, search, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            }),
            responseType: 'arraybuffer'
        }).pipe(tap((x) => {
        }),
            catchError(this.handleError<RptMaterialSearch>('SearchWorkPermit'))
        );
    }

    ExportExcel(dateStart: Date, dateEnd: Date): Observable<any> {
        const httpOptions = {
            responseType: 'blob' as 'json'
        };

        return this.http.post<any>(BASEURL + 'api/EOderingAttachmentReport/Export/', { DateStart: dateStart, DateEnd: dateEnd }, httpOptions);
    }
















    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a ScgCompanyService message with the MessageService */
    private log(message: string) {
        console.log(message);
        // this.messageService.add('ScgCompanyService: ' + message);
    }


}
