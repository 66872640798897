export const Permission = {
  // RE_PACKING: 'RPK100',
  // BOM: 'BOM100',
  // STOCK_ADJUST: 'ADT100',
  // STOCK_TAKING: 'STK100',
  // CUSTOMER: 'CMR100',
  // STOCK_ITEM_FG: 'FST100',
  // HOME: 'HMS100',
  // ITEM_RECEIVE: 'IRC100',
  ITEM_MASTER: 'ITM100',
  // ITEM_CATEGORY: 'ITM200',
  LOCATION: 'LMT100',
  // MATERIAL_RECEIVE: 'MRC101',
  // MISCELLANEOUS: 'MSC100',
  // STOCKITEM_MAT: 'MST100',
  // STOCK_TRANSFER: 'STF100',
  USER: 'USR100',
  PERMISSION: 'GRP100',
  // SUPPLIER: 'VDR100',
  WAREHOUSE: 'WMT100',
  // IQC_INSPECTION: 'INS100',
  // SQE_INSPECTION: 'INS200',
  // PRODUCTION_RESULT: 'PRD100',
  // PRODUCTION_RESULT_P2: 'PRD200',
  // SHIPPING_INSTRUCTION: 'SAL100',
  // PACKING: 'PAK100',
  // FG_INSPECTION: 'INF100',
  // REPORT_MATERAIL_RECEIVE_DETAIL: 'RPT100',
  // REPORT_MATERAIL_RECEIVE_SUMMARY: 'RPT150',
  // REPORT_IQC_INSPECTION: 'RPT200',
  // REPORT_SQE_INSPECTION: 'RPT250',
  // REPORT_PRODUCTION_RESULT: 'RPT300',
  // REPORT_FG_INSPECTION: 'RPT350',
  // REPORT_INVENTORY_MOVEMENT_DETAIL: 'RPT400',
  // REPORT_INVENTORY_MOVEMENT_SUMMARY: 'RPT410',
  // REPORT_INVENTORY_TRANSACTION: 'RPT420',
  // REPORT_STOCK_BALANCE: 'RPT430'
  AGMList: 'AGM100'
  , AGMManage: 'AGM110'
  , AGMDetail: 'AGM120'
  , AGMApproveList: 'AGM200'
  , AGMApprove: 'AGM210'
  , AGMTemplateList: 'AGM300'
  , AGMTemplateManage: 'AGM310'
  , AGMTemplateDetail: 'AGM320'
  , Benefit: 'BNF001'
  , BenefitRequest: 'BNF100'
  , BenefitApprove: 'BNF200'
  , BenefitReport: 'BNF300'
  , ContractList: 'CTR100'
  , ContractManage: 'CTR110'
  , ContractDetail: 'CTR120'
  , ContractApproveList: 'CTR200'
  , ContractApprove: 'CTR210'
  , ContractTemplateList: 'CTR300'
  , ContractTemplateDetail: 'CTR310'
  , ContractTemplateManage: 'CTR320'
  , Report1: "PRT100"
  , Report2: "PRT200"
  , Report3: "PRT300"
  , PIList: 'PI100'
  , PIManage: 'PI110'
  , PIDetail: 'PI120'
  , PIApproveList: 'PI200'
  , Supplierlist: 'MAS100'
  , SettingDC: 'DC100'
  , SettingMER: 'MER200'
  , DSSCConfirmDelivery: 'CD200'
  , SupplierManage: 'MAS110'
  , SupplierDetail: 'MAS120'
  , PIApprovalSetting: 'MAS200'
  , PIImportApprovalSetting: 'MAS300'
  , GoodsIssue: 'GI000'
  , GIGR: 'GI100'
  , CancelGoodsIssue: 'GI110'
  , GoodsReturn: 'GR100'
  , GoodIssueDirect: 'GI101'
  , GoodIssueByPass: 'GI102'
  , GoodIssueDirectDist: 'GI103'
  , CancelGoodsIssueDirect: 'GI111'
  , CancelGoodsIssueByPass: 'GI112'
  , CancelGoodsIssueDirectDist: 'GI113'
  , GoodsReturnDirect: 'GR101'
  , GoodsReturnByPass: 'GR102'
  , GoodsReturnDirectDist: 'GR103'
  , ContractTemplateHeader: 'CTR321'
  , ContractTemplateFooter: 'CTR322'
  , ContractTemplateNo1: 'CTR323'
  , ContractTemplateNo2: 'CTR324'
  , ContractTemplateNo3: 'CTR325'
  , ContractTemplateNo4: 'CTR326'
  , ContractTemplateNo5: 'CTR327'
  , ContractTemplateNo6: 'CTR328'
  , ContractTemplateNo7: 'CTR329'
  , ContractTemplateNo8: 'CTR330'
  , ContractTemplateNo9: 'CTR331'
  , ContractTemplateNo10: 'CTR332'
  , ContractTemplateNo11: 'CTR333'
  , ContractTemplateNo12: 'CTR334'
  , ContractTemplateNo13: 'CTR335'
  , ContractTemplateNo14: 'CTR336'
  , ContractTemplateNo15: 'CTR337'
  , ContractTemplateNo16: 'CTR338'
  , ContractTemplateNo17: 'CTR339'
  , ContractTemplateNo18: 'CTR340'
  , ContractTemplateNo19: 'CTR341'
  , ContractTemplateNo20: 'CTR342'
  , ChangDelivery: 'GI120'
  , ChangeDeliveryขายตรง: 'GI121'
  , ChangeDeliveryขายผ่าน: 'GI122'
  , ChangeDeliveryขายตรงDist: 'GI123'
  , BookingList: 'BK100'
  , ConfirmDeliveryList: "CD100"
  , SupplierPiList: 'PI300'
  , SupplierPiApprove: 'PI400'
};

export const Action_Permission = {
  VIEW: 100,
  CREATE: 150,
  EDIT: 200,
  PRINT: 300,
  APPROVE: 400,
  DELETE: 500,
  EXPORT: 600,
  IMPORT: 999
};

export const Action_PermissionContract = {
  VIEW: 100,
  CREATE: 150,
  EDIT: 200,
  PRINT: 300,
  // CHECK: 400,
  APPROVE: 400,
  DELETE: 600,
  SEND_EMAIL: 700,
  IMPORT: 800,
  EXPORT: 900,
  ASSIGN: 1000
};
