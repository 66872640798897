import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToggleFullScreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { AccordionAnchorDirective } from './accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './accordion/accordionlink.directive';
import { AccordionDirective } from './accordion/accordion.directive';
import { HttpClientModule } from '@angular/common/http';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TitleComponent } from '../layout/admin/title/title.component';
import { CardComponent } from './card/card.component';
import { CardToggleDirective } from './card/card-toggle.directive';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgbDateCustomParserFormatter } from '../directive/NgbDateCustomParserFormatter';
import { NumbersOnlyDirective, OnlyNumberDirective, TwoDigitDecimaNumberDirective } from '../directive/only-number.directive';
import { ModalCustomimzeComponent } from './modal-basic-customize/modal-customize.component';
import { DisableControlDirective } from '../directive/DisableControl.directive';
import { NumberFormatPipe } from '../pipe/number-pipe.pipe';
import { ModalBasicPdfComponent } from './modal-basic-pdf/modal-basic-pdf.component';
import { GroupByPipe, MaxPipe, SumPipe } from '../directive/Pipe.extentions';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [CommonModule, NgbModule.forRoot(), HttpClientModule, PerfectScrollbarModule, ClickOutsideModule],
  exports: [
    NgbModule,
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    HttpClientModule,
    PerfectScrollbarModule,
    NumberFormatPipe,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    ModalCustomimzeComponent,
    ModalBasicPdfComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    ClickOutsideModule,
    NumbersOnlyDirective,
    OnlyNumberDirective,
    DisableControlDirective,
    TwoDigitDecimaNumberDirective,
    GroupByPipe,
    SumPipe,
    MaxPipe,
  ],
  declarations: [
    GroupByPipe,
    SumPipe,
    MaxPipe,
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    TitleComponent,
    CardComponent,
    NumberFormatPipe,
    ModalCustomimzeComponent,
    NumbersOnlyDirective,
    OnlyNumberDirective,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    DisableControlDirective,
    ModalBasicPdfComponent,
    TwoDigitDecimaNumberDirective,
  ],

  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class SharedModule { }
