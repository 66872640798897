import { log } from 'util';
import { User } from './../model/user.model';
import { AuthenService } from './../services/authen.service';
import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import swal from 'sweetalert2';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private CountTime: number = 0;
    private RefershTokenTime_Secound = (3600 * 6);
    constructor(private _authService: AuthenService,  @Inject('BASE_URL') private baseUrl: string,private spinnerService: Ng4LoadingSpinnerService, ) {
        this.baseUrl =baseUrl
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        let currentUser:User = JSON.parse(localStorage.getItem('currentUser'));
        // console.log("Time : ", this.CountTime);
        // if (this.CountTime > this.RefershTokenTime_Secound && this.CountTime < (this.RefershTokenTime_Secound * 2)) {
        //     this.CountTime = 0;
        //     this.RefreshToken();
        // }
        
        // console.log("AuthInterceptor", currentUser);
        // debugger;
        if(!request.url.includes(this.baseUrl)) //other api
            return next.handle(request);
        var x = ["/api/authentication/login",'/api/authentication/verify'];
        for (let index = 0; index < x.length; index++) { //allow api
            const element = x[index];
            if(request.url.includes(element))
            return next.handle(request);
        }
        if ( currentUser && currentUser.token) {

            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(currentUser.token);
            const expirationDate = helper.getTokenExpirationDate(currentUser.token);
            const isExpired = helper.isTokenExpired(currentUser.token);
            if(isExpired){
                console.log(request.url);
                this.spinnerService.hide();
                swal({
                    title: 'เกิดข้อผิดพลาด!',
                    // text: msgError,
                    html: "Session ของคุณหมดอายุ กรุณา Login อีกครั้ง",
                    type: 'error',
                    confirmButtonText: 'ปิด',
                    allowOutsideClick: true
                  }).then(c => {
                    this._authService.logout();
                  });   
            }
            else{
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
            return next.handle(request);
          }
        }
        else{
            console.log(request.url);
            this.spinnerService.hide();
            swal({
                title: 'เกิดข้อผิดพลาด!',
                // text: msgError,
                html: "Your token is invalid or has expired. Please login again",
                type: 'error',
                confirmButtonText: 'ปิด',
                allowOutsideClick: true
              }).then(c => {
                this._authService.logout();
              });
        }
      
    }

    // RefreshToken() {
    //     this._authService.GetNewToken().subscribe(res => {
    //         let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //         // console.log("OLD", currentUser.Token);
    //         currentUser.Token = res.Token;
    //         // console.log("NEW", currentUser.Token);
    //         window.localStorage.setItem('currentUser', JSON.stringify(currentUser));
    //     });
    // }

    // interval = setInterval(() => {
    //     this.CountTime++;
    //     // console.log("Thick");
    // }, 1000);


}
