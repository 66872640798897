import { AgmAttachment, AgmInvoiceAttachment } from '../model/setting.model';
import { ReturnApi } from '../model/Common.model';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalService } from './modal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { PIAttachment, AgmSelectedTab, AgreementList_Search, AgreementList, AgmHeader, UpdateStatusAgreement, AgmAprroval, ApprovAgmModel } from '../model/agreement.model';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { MasterSearch, GetSupplierList, GetSupplierBySupplierId, UpdateSupplier, Period } from '../model/master.model';
import { BenefitRequestClaimResponse as BenefitRequestClaimResponse } from '../model/benefit-claim-list.model';


// const httpOptions = {
//     headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
//       'Access-Control-Allow-Origin': '*',
//       'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
//       'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
//     })
//   };
const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};
const API_Url = environment;
let BASEURL = '';
@Injectable()
export class BenefitService {

  uploadFile(id, data: FormData): Observable<ReturnApi> {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          // 'Content-Type': 'application/json',
          // 'Authorization': (this.currentUser && this.currentUser.token) ? 'Bearer ' + this.currentUser.token : ''
        }
      )
    };

    return this.http.post<ReturnApi>(BASEURL + 'api/benefit/' + id + "/uploadBenefitAttachment/", data, httpOptions).pipe(
      tap((res: any) => { },
        catchError(this.handleError<FormData>('uploadError')))
    );
  }
  uploadpimanageAttachment(id, data: FormData): Observable<ReturnApi> {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          // 'Content-Type': 'application/json',
          // 'Authorization': (this.currentUser && this.currentUser.token) ? 'Bearer ' + this.currentUser.token : ''
        }
      )
    };

    return this.http.post<ReturnApi>(BASEURL + 'api/benefit/' + id + "/uploadpimanageAttachment/", data, httpOptions).pipe(
      tap((res: any) => { },
        catchError(this.handleError<FormData>('uploadError')))
    );
  }


  constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;

  }
  GetBenefitRequestBySupplierCode(search: any, id: any, type: any, number: any) {
    return this.http.get<any>(BASEURL + 'api/benefit/GetNewBenefitRequestBySupplierCode/' + search + '/' + id + '/' + type + '/' + number, httpOptions).pipe(tap(response => { }));
  }

  GetBenefitRequestClaimList(value: any): Observable<BenefitRequestClaimResponse[]> {
    return this.http.post<any>(BASEURL + 'api/benefit/GetBenefitRequestClaimList', value, httpOptions).pipe(tap(response => { }));
  }
  ExportBenefitRequestClaimList(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/benefit/ExportBenefitRequestClaimList', value, httpOptions).pipe(tap(response => { }));
  }

  getLoghistory(value: any): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/benefit/GetLoghistory/' + value);
  }
  GetBenefitRequestByidView(search: any) {
    return this.http.get<any>(BASEURL + 'api/benefit/GetBenefitRequestByidView/' + search, httpOptions).pipe(tap(response => { }));
  }
  GetNewBenefitRequestByidView(search: any, id: any, type: any, number: any) {
    return this.http.get<any>(BASEURL + 'api/benefit/GetNewBenefitRequestByidView/' + search + '/' + id + '/' + type + '/' + number, httpOptions).pipe(tap(response => { }));
  }
  BenefitManage(manageObj: any) {
    return this.http.post<any>(BASEURL + 'api/benefit/BenefitManage', manageObj, httpOptions).pipe(tap(response => { }));
  }
  DeleteBenefitAttachment(row: any) {
    return this.http.post<any>(BASEURL + 'api/benefit/DeleteBenefitAttachment', row, httpOptions).pipe(tap(response => { }));
  }
  BenefitMDeleteBenefitAttachmentanage(manageObj: any) {
    return this.http.post<any>(BASEURL + 'api/benefit/DeleteBenefitAttachment', manageObj, httpOptions).pipe(tap(response => { }));
  }
  GetBenefitList(search): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/benefit/GetBenefitList', search, httpOptions).pipe(tap(response => { }));
  }

  GetBenefitReportCreateByList(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/benefit/GetBenefitReportCreateByList', httpOptions).pipe(tap(response => { }));
  }
  GetBenefitReportClaimList(search): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/benefit/GetBenefitReportClaimList', search, httpOptions).pipe(tap(response => { }));
  }
  ExportBenefitReportClaimList(search): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/benefit/ExportBenefitReportClaimList', search, httpOptions).pipe(tap(response => { }));
  }
  GetBenefitTypeList(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/benefit/GetBenefitTypeList', httpOptions).pipe(tap(response => { }));
  }
  GetBenefitAvailableInvoiceUploadByFilter(search): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/benefit/GetAvailableInvoiceUploadByFilter', search, httpOptions).pipe(tap(response => { }));
  }

  uploadInvoiceFile(id: number, id2: number, formData: FormData): Observable<ReturnApi> {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          // 'Content-Type': 'application/json',
          // 'Authorization': (this.currentUser && this.currentUser.token) ? 'Bearer ' + this.currentUser.token : ''
        }
      )
    };

    return this.http.post<ReturnApi>(BASEURL + "api/benefit/" + id + "/" + id2 + "/UploadInvoice", formData, httpOptions).pipe(
      tap((res: any) => { },
        catchError(this.handleError<FormData>('uploadError')))
    );
  }
  DownloadInvoiceFile(data: AgmInvoiceAttachment): Observable<any> {
    return this.http.post(BASEURL + 'api/benefit/DownloadInvoice', data, httpOptions)
  }

  BenefitRequestApprove(manageObj: any) {
    return this.http.post<any>(BASEURL + 'api/benefit/BenefitRequestApprove', manageObj, httpOptions).pipe(tap(response => { }));
  }
  uploadFileApprove(id, data: FormData): Observable<ReturnApi> {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          // 'Content-Type': 'application/json',
          // 'Authorization': (this.currentUser && this.currentUser.token) ? 'Bearer ' + this.currentUser.token : ''
        }
      )
    };

    return this.http.post<ReturnApi>(BASEURL + 'api/benefit/' + id + "/uploadBenefitAttachmentSupplier/", data, httpOptions).pipe(
      tap((res: any) => { },
        catchError(this.handleError<FormData>('uploadError')))
    );
  }
  GetBenefitRequestClaimApproveList(param): Observable<BenefitRequestClaimResponse[]> {
    return this.http.post<any>(BASEURL + 'api/benefit/GetBenefitRequestClaimApproveList', param, httpOptions).pipe(tap(response => { }));

  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logA(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ScgCompanyService message with the MessageService */
  private logA(message: string) {
    console.log(message);
    // this.messageService.add('ScgCompanyService: ' + message);
  }
}

