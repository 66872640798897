import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal-customize',
  templateUrl: './modal-customize.component.html',
  styleUrls: ['./modal-customize.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalCustomimzeComponent implements OnInit {
  @Input() dialogClass: string;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  public visible = false;
  public visibleAnimate = false;

  constructor() { }

  ngOnInit() {

  }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }

  public onContainerClicked(event: MouseEvent): void {
    // if ((<HTMLElement>event.target).classList.contains('modal')) {
    //   this.hide();
    // }
  }

}
