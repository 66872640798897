
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { _throw as throwError } from 'rxjs/observable/throw';
import { catchError } from 'rxjs/operators';
import { User } from '../model/user.model';
import { AuthenService } from '../services/authen.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthenService
        , private _router: Router
        , private _loading: Ng4LoadingSpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                console.log("ErrorInterceptor", err.status);
                // auto logout if 401 response returned from api
                let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                // let u: User = new User();
                // u.LoginID = currentUser.LoginID;
                swal({
                    title: 'เกิดข้อผิดพลาด!',
                    // text: msgError,
                    html: "Your token is invalid or has expired. Please login again",
                    type: 'error',
                    confirmButtonText: 'ปิด',
                    allowOutsideClick: true
                  }).then(c => {
                    this._authService.logout();
                  });
                //this._authService.logout();
                // this._authService.logout(u).subscribe(
                //     data => {
                //         if (data.IsDone) {
                //             window.localStorage.removeItem('currentUser');
                //             this._router.navigate(['/auth/login']);
                //             this._loading.hide();
                //             console.log("logout")
                //             // location.reload(true);
                //         }

                //     }), (err) => {
                //         window.localStorage.removeItem('currentUser');
                //         this._router.navigate(['/auth/login']);
                //         this._loading.hide();
                //         console.log("logout")
                //     };
            }
            else if (err.status === 400) {
                //var errString = new TextDecoder().decode(err.error);
                var errString = new TextDecoder()

            if(err.error)
            {
                errString.decode(err.error);
            }
            else
            {
                errString.decode(null)
            }


                if(errString)
                {
                    this._loading.hide();
                swal({
                    title: 'เกิดข้อผิดพลาด!',
                    // text: msgError,
                    html: errString,
                    type: 'error',
                    confirmButtonText: 'ปิด',
                    allowOutsideClick: true
                  })
                }
                return //throwError(err);
            }           
           else if(err.status === 500){
                return throwError(err);
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}
