import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AuthGuard } from './guard/_guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: '',
      //   redirectTo: 'dashboard/default',
      //   pathMatch: 'full'
      // },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'purchaseorder',
        loadChildren:
          './pages/purchase-order/pi-order/pi-order.module#PiOrderModule'
      },
      {
        path: 'purchaseorder-approve',
        loadChildren:
          './pages/purchase-approve/pi-approve.module#PiApproveModule'
      },
      {
        path: 'confirm-delivery',
        loadChildren:
          './pages/confirm-delivery/confirm-delivery.module#ConfirmDeliveryModule'
      },
      {
        path: 'goods-issue',
        loadChildren:
          './pages/e-pur/goods-issue/goods-issue.module#GoodsIssueModule'
      },
      {
        path: 'return-goods',
        loadChildren:
          './pages/e-pur/returngoods/returngoods.module#ReturngoodsModule'
      },
      {
        path: 'cancel-goods',
        loadChildren:
          './pages/e-pur/cancleCutoff-goods/cancleCutoff-goods.module#CancleCutoffGoodsModule'
      },
      {
        path: 'edit-delivery',
        loadChildren:
          './pages/e-pur/edit-Delivery/edit-delivery.module#EditDeliveryModule'
      },
      {
        path: 'benefit',
        loadChildren:
          './pages/benefit/benefit.module#BenefitModule'
      },
      // {
      //   path: 'agreement-manage',
      //   loadChildren:
      //     './pages/e-pur/agreement-manage/agreement-manage.module#AgreementManageModule'
      // },
      // {
      //   path: 'contract-manage',
      //   loadChildren:
      //     './pages/e-pur/contract-manage/contract-manage.module#ContractManageModule'
      // },
      {
        path: 'preview-pdf',
        loadChildren:
          './pages/test-preview-pdf/test-preview-pdf.module#TestPreviewPdfModule'
      },
      {
        path: 'text-editor',
        loadChildren:
          './pages/test-text-editor/test-text-editor.module#TestTextEditorModule'
      },
      {
        path: 'drag-drop',
        loadChildren:
          './pages/test-drag-and-drop/test-drag-and-drop.module#TestDragAndDropModule'
      },
      {
        path: 'htmlpdf',
        loadChildren:
          './pages/test-htmlpdf/test-htmlpdf.module#TestHtmlpdfModule'
      },
      {
        path: 'contract',
        loadChildren:
          './pages/contract/contract.module#ContractModule'
      },
      {
        path: 'template',
        loadChildren:
          './pages/template/template.module#TemplateModule'
      },
      {
        path: 'pi/pi-import',
        loadChildren:
          // './pages/template/template.module#TemplateModule'
          // './pages/pi-import/pi-import.module#TemplateModule'
          './pages/pi-import/pi-import.module#PiImportModule'
      },
      {
        path: 'pi',
        loadChildren:
          './pages/pi/pi.module#PiModule'
      },
      {
        path: 'approve',
        loadChildren: './pages/Approve/approve.module#ApproveModule'
      },
      {
        path: 'approve-so',
        loadChildren: './pages/approve-so/approve-so.module#ApproveSOModule'
      },
      {
        path: 'supplier',
        loadChildren: './pages/Supplier/supplier.module#SupplierModule'
      },
      {
        path: 'booking',
        loadChildren: './pages/booking-queue/booking/booking.module#BookingModule'
      },
      {
        path: 'check-queue',
        loadChildren: './pages/booking-queue/check-queue/check-queue.module#CheckQueueModule'
      },
      {
        path: 'booking-schedule',
        loadChildren: './pages/booking-queue/booking-schedule/booking-schedule.module#BookingScheduleModule'
      },
      {
        path: 'dashboard',
        loadChildren: './pages/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'navigation',
        loadChildren: './theme/navigation/navigation.module#NavigationModule'
      },
      {
        path: 'widget',
        loadChildren: './theme/widget/widget.module#WidgetModule'
      },
      {
        path: 'basic',
        loadChildren: './theme/ui-elements/basic/basic.module#BasicModule'
      },
      {
        path: 'advance',
        loadChildren: './theme/ui-elements/advance/advance.module#AdvanceModule'
      },
      {
        path: 'animations',
        loadChildren:
          './theme/ui-elements/animation/animation.module#AnimationModule'
      },
      {
        path: 'forms',
        loadChildren: './theme/forms/forms.module#FormsModule'
      },
      {
        path: 'bootstrap-table',
        loadChildren:
          './theme/table/bootstrap-table/bootstrap-table.module#BootstrapTableModule'
      },
      {
        path: 'data-table',
        loadChildren:
          './theme/table/data-table/data-table.module#DataTableModule'
      },
      {
        path: 'maintenance/error',
        loadChildren: './theme/maintenance/error/error.module#ErrorModule'
      },
      {
        path: 'maintenance/coming-soon',
        loadChildren:
          './theme/maintenance/coming-soon/coming-soon.module#ComingSoonModule'
      },
      {
        path: 'usertemplate',
        loadChildren: './theme/user/user.module#UserTemplateModule'
      },
      {
        path: 'task',
        loadChildren: './theme/task/task.module#TaskModule'
      },

      {
        path: 'user',
        loadChildren: './pages/user/user.module#UserModule'
      },
      {
        path: 'grouppermission',
        loadChildren:
          './pages/group-permission/group-permission.module#GroupPermissionModule'
      },

      {
        path: 'file-upload-ui',
        loadChildren:
          './theme/extension/file-upload-ui/file-upload-ui.module#FileUploadUiModule'
      },
      {
        path: 'charts',
        loadChildren: './theme/chart/chart.module#ChartModule'
      },
      {
        path: 'map',
        loadChildren: './theme/map/map.module#MapModule'
      },
      {
        path: 'simple-page',
        loadChildren: './theme/simple-page/simple-page.module#SimplePageModule'
      },
      {
        path: 'location',
        loadChildren:
          './pages/location/location.module#LocationModule'
      },
      {
        path: 'pi/pur-group',
        loadChildren:
          './pages/pur-group/pur-group.module#PurGroupModule'
      },
      {
        path: 'test-shedule',
        loadChildren:
          './pages/test-scheduler/test-scheduler.module#TestSchedulerModule'
      },
      {
        path: 'test-shedule-weekly',
        loadChildren:
          './pages/test-scheduler-weekly/test-scheduler-weekly.module#TestSchedulerWeeklyModule'
      },
      {
        path: 'setting-DC',
        loadChildren:
          './pages/settings/setting-dc/setting-dc.module#SettingDcModule'
      },

      {
        path: 'GIGR',
        loadChildren: './pages/gigr/gigr.module#GiGrModule'
      },
      {
        path: 'change-delivery',
        loadChildren: './pages/change-delivery/change-delivery.module#ChangeDeliveryModule'
      },
      {
        path: 'confirm-delivery-list-soap',
        loadChildren: './pages/confirm-delivery-soap/confirm-delivery-soap.module#ConfirmDeliverySoapModule'
      },
      {
        path: 'report',
        loadChildren: './pages/report/report.module#ReportModule'
      },
      //   { 
      //   path: 'test-shedule2',
      //   loadChildren:
      //     './pages/calendar-preview/calendar-preview.module#CalendarPreviewModule'
      // }, 
    ]
  },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  // },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'not-authorized',
        loadChildren: './pages/not-authorized/not-authorized.module#NotAuthorizedModule'
      },
      {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginModule'
      },
      {
        path: 'auth',
        loadChildren: './theme/auth/auth.module#AuthModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren:
          './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
