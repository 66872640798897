import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable()
export class ModalService {

  constructor() {

  }
  openErrorhtml(msgError: string) {
    swal({
      title: 'Error!',
      html: msgError,
      type: 'error',
      confirmButtonText: 'Close',
      allowOutsideClick: true
    }).catch(swal.noop);
  }
  openErrorhtmlWithHeader(Header, msgError: string, customContainerClass: string = '') {
    swal({
      title: Header,
      html: msgError,
      type: 'error',
      customContainerClass: customContainerClass,
      confirmButtonText: 'Close',
      allowOutsideClick: true,
    }).catch(swal.noop);
  }

  openErrorhtmlWithHeader2(Header, msgError: string, customContainerClass: string = '') {
    return swal({
      title: Header,
      html: msgError,
      type: 'error',
      customContainerClass: customContainerClass,
      confirmButtonText: 'Close',
      allowOutsideClick: false,
    }).catch(swal.noop);
  }
  openError(msgError: string) {
    swal({
      title: 'เกิดข้อผิดพลาด!',
      // text: msgError,
      html: msgError,
      type: 'error',
      confirmButtonText: 'ปิด',
      allowOutsideClick: true
    }).catch(swal.noop);
  }
  openError2(msgError: string) {
    return swal({
      title: 'เกิดข้อผิดพลาด!',
      // text: msgError,
      html: msgError,
      type: 'error',
      confirmButtonText: 'ปิด',
      allowOutsideClick: true
    });
  }

  openSuccess(msg: string) {
    swal({
      title: 'สำเร็จ',
      text: msg,
      type: 'success',
    }).catch(swal.noop);
  }
  openSuccessAgm() {
    return swal({
      title: ' บันทึกการอนุมัติเรียบร้อยแล้ว',
      type: 'success',
    });
  }
  openSuccessRejectAgm() {
    return swal({
      title: ' บันทึกการอนุมัติยกเลิกเรียบร้อยแล้ว',
      type: 'success',
    });
  }

  openSuccessUser(msg: string) {
    return swal({
      title: 'สำเร็จ',
      text: msg,
      type: 'success',
      allowOutsideClick: false,
    }).catch(swal.noop);
  }
  openWarning(msg: string) {
    return swal({
      title: 'Warning!',
      html: msg,
      type: 'warning',
      showCancelButton: false,
      confirmButtonText: 'ปิด',
      allowOutsideClick: true,
    });
  }

  openWarning2(msg: string) {
    return swal({
      title: 'แจ้งเตือน!',
      html: msg,
      type: 'warning',
      showCancelButton: false,
      confirmButtonText: 'ปิด',
      allowOutsideClick: true,
    });
  }

  openConfirmhtml(msg: string) {
    return swal({
      title: 'Are you sure?',
      html: msg,
      type: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Auto print DN',
      allowOutsideClick: false,
    });
  }

  openerrorvalidate(msg: string) {
    return swal({
      title: 'กรุณากรอกข้อมูล',
      text: msg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ปิด',
      //cancelButtonText: 'ไม่',
      allowOutsideClick: false,
    });
  }

  openConfirmre(msg: string, type: string) {
    return swal({
      title: 'ยืนยันยกเลิกการรับคืน',
      text: msg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
      allowOutsideClick: false,
    });
  }

  openConfirmLossItem(msg: string){
    return swal({
      title: 'Negative Margin Warning',
      html: msg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      allowOutsideClick: false
    });
  }

  openConfirmLossItemApprove(msg: string){
    return swal({
      title: 'Approve Negative Margin?',
      html: msg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
      allowOutsideClick: false,
      showCloseButton: true
    });
  }

  openConfirm(msg: string, type: string) {
    let text = '';
    if (type == 'G') {
      text = 'ยืนยันการตัดจ่าย';
    } else if (type == 'C') {
      text = 'ยืนยันการยกเลิกตัดจ่าย';
    } else if (type == 'R') {
      text = 'ยืนยันการรับคืน';
    } else if (type == 'R_C') {
      text = 'ยืนยันยกเลิกการรับคืนใช่หรือไม่';
    } else if (type == 'Z') {
      text = 'คุณต้องการใส่ 0 ใช่หรือไม่';
    }else if (type == 'plant') {
      text = 'คุณต้องการเปลี่ยน Plant ใช่หรือไม่';
    }else {
      text = type;
    }

    return swal({
      title: text,
      html: msg,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ใช่',
      cancelButtonText: 'ไม่',
      allowOutsideClick: false,
    });
  }
  dataError(msgError: string) {
    swal({
      title: 'ไม่สามารถตัดจ่ายได้เนื่องจาก',
      text: msgError,
      html: '(มีสถานะที่ถูกตัดจ่ายไปแล้ว)',
      type: 'error',
      confirmButtonText: 'OK',
      allowOutsideClick: true,
    }).catch(swal.noop);
  }

  openSuccess2(msg: string) {
    return swal({
      title: 'สำเร็จ',
      html: msg,
      // text: msg,
      type: 'success',
      confirmButtonText: 'ปิด',
      allowOutsideClick: false
    });
  }

  openErrorGI(msgError: string) {
    return swal({
      title: 'เกิดข้อผิดพลาด!',
      // text: msgError,
      html: msgError,
      type: 'error',
      confirmButtonText: 'ปิด',
      allowOutsideClick: true
    }).catch(swal.noop);
  }
}
