import { Item } from './item.model';
import { CancleCutoffGoodsComponent } from './../pages/e-pur/cancleCutoff-goods/cancleCutoff-goods.component';
import { T_RETURN_CLASS } from './Common.model';
export class SmartItem {
  item: Array<any>;
}

export class GET_DELIVERY_EXPORT {
  ET_DN_HEADER: SmartItem;
  ET_RETURN: SmartItem;
  ET_DN_ITEM: SmartItem;
  Connection_MAIN: string;
  Massage_MAIN: string;
  STATUS_MAIN: string;
  Connection_LOG: string;
  Massage_LOG: string;
  STATUS_LOG: string;
}

export class GET_GROUP_DN_EXPORT {
  T_GROUPDN: SmartItem;
  T_RETURN: SmartItem;
}

export class GI_EXPORT {
  T_RETURN: SmartItem;
}
export class T_DN_HEADER_CLASS {
  VBELNS: string[];
  VBELN: string;
  VKORG: string;
  VSTEL: string;
  LFART: string;
  KUNAG: string;
  SOLDTO_NAME: string;
  LFDAT: string;
  WADAT_IST: string;
  WAUHR: string;
  INCO1: string;
  INCO1_DESC: string;
  VSBED: string;
  SHPTCON_DESC: string;
  WBSTK: string;
  ERDAT: string;
  ERNAM: string;
  DRIVER_NAME: string;
  CAR_LICENSE: string;
  DRIVER_LICENSE: string;
  FORWARDING_AGENT: string;
  ZTERM: string;
  PMNT_DESC: string;
  VGBEL: string;
  AUART: string;
  BUKRS: string;
  COM_NAME: string;
  ADDR1_COM: string;
  ADDR2_COM: string;
  ADDR3_COM: string;
  POSTCODE_COM: string;
  TELEP_COM: string;
  TAXID_COM: string;
  KUNNR: string;
  SHIPTO_NAME: string;
  ADDR1_SHIPTO: string;
  ADDR2_SHIPTO: string;
  ADDR3_SHIPTO: string;
  POSTCODE_SHIPTO: string;
  TELEP_SHIPTO: string;
  ADDR1_SOLDTO: string;
  ADDR2_SOLDTO: string;
  ADDR3_SOLDTO: string;
  POBOXCITY_SHIPTO: string;
  POSTCODE_SOLDTO: string;
  TELEP_SOLDTO: string;
  BSTKD: string;
  LIFEX: string;
  LIFSK: string;
  DN_EXP_DATE: string;
  T_GROUPDN: T_GROUPDN_CLASS[];
}



export class T_GROUPDN_CLASS {
  SMART: string;
  SAMMG: string;
  ERDAT: Date;
  ERNAM: string;
  VSTEL: string;
  VBELN: string;
  SORTF: string;
  WEGIHT: string;
  T_DN_ITEM: Array<T_DN_ITEM_CLASS_2>;
  constructor() {
    this.T_DN_ITEM = new Array<T_DN_ITEM_CLASS_2>();
  }
}

export class T_DN_ITEM_CLASS {
  POSEX: string;
  VBELN: string;
  POSNR: string;
  PSTYV: string;
  MATNR: string;
  BISMT: string;
  PRODH: string;
  LFIMG2: string;
  VRKME: string;
  SALESUNIT_DESC: string;
  WERKS: string;
  PLANT_NAME: string;
  LGORT: string;
  EAN11: string;
  MAKTX_EN: string;
  MAKTX_TH: string;
  WEGIHT: number;
  WEIGHT_UNIT: string;
  LGMNG: number;
  MEINS: string;
  UMVKN: string;
  KOSTA: string;
  VTWEG: string;
  SPART: string;
  BSTKD: string;
  UEPOS: string;
  UECHA: string;
  CHARG: string;
}

export class T_DN_ITEM_CLASS_2 {
  POSEX: string;
  index: string;
  BatchType: string;
  BatchGroup: number;
  BATCHPRICE: number;
  BatchNumber: string;
  HaveBatch: boolean;
  LFIMG2: number;
  Total: number;
  /**Add With Change Batch */
  Disable: string;
  VBELN: string;
  POSNR: string;
  PSTYV: string;
  MATNR: string;
  BISMT: string;
  PRODH: string;
  LFIMG: number;
  VRKME: string;
  SALESUNIT_DESC: string;
  WERKS: string;
  PLANT_NAME: string;
  LGORT: string;
  EAN11: string;
  MAKTX_EN: string;
  MAKTX_TH: string;
  WEIGHT: string;
  WEIGHT_UNIT: string;
  LGMNG: number;
  MEINS: string;
  UMVKN: string;
  KOSTA: string;
  VTWEG: string;
  SPART: string;
  BSTKD: string;
  UEPOS: string;
  UECHA: string;
  CHARG: string;
  MAT_REQUIRE: string;
}

export class GET_DELIVERY_IMPORT {
  I_APP_NAME: string;
  I_INF_NAME: string;
  I_INF_ID: string;
  I_SAPPI_ID: string;
  I_VBELN: string;
  I_LIFEX: string;
  I_DN_DATE_FR: Date;
  I_DN_DATE_TO: Date;
  I_DN_STATUS: string;
  I_VKORG: string;
  I_VSTEL: string;
  I_KUNAG: string;
  I_KUNNR: string;
  I_GI_DATE_FR: Date;
  I_GI_DATE_TO: Date;
  I_CREATE_DATE_FR: Date;
  I_CREATE_DATE_TO: Date;
  Distributions: any;
  DeliveryPacking: any;
  SearchPG: any;
  SearchDN: any;
  car: string;
}

export class GET_PACKING_IMPORT {
  I_APP_NAME: string;
  I_INF_NAME: string;
  I_INF_ID: string;
  I_SAPPI_ID: string;
  I_SMART: string;
  I_SAMMG: string;
  I_VSTEL: string;
  I_ERNAM: string;
  I_CREATE_DATE_FR: string;
  I_CREATE_DATE_TO: string;
}

export class GET_GROUP_DN_IMPORT {
  I_APP_NAME: string;
  I_INF_NAME: string;
  I_INF_ID: string;
  I_SAPPI_ID: string;
  I_SMART: string;
  I_SAMMG: string;
  I_VSTEL: string;
  I_ERNAM: string;
  I_CREATE_DATE_FR: string;
  I_CREATE_DATE_TO: string;
}

export class LogGetDelivery {
  SaleChanel: string;
  APIName: string;
  ServerName: string;
  Parameter: string;
  ConnectStatus: string;
  Message: string;
  CreateDate: string;
  CreateBy: string;
}

export class GIConfirm {
  I_APP_NAME: string;
  I_FLAG_SO: string;
  I_GI_DATE: string;
  I_INF_ID: string;
  I_INF_NAME: string;
  I_LIFEX: string;
  I_OPTION: string;
  I_SAPPI_ID: string;
  I_VBELN: string;
  T_ITEM_PICKING: SmartItem;
  constructor() {
    this.T_ITEM_PICKING = new SmartItem();
  }
}

export class ITEMlist {
  VBLEN: string;
  T_ITEM_PICKING: T_DN_ITEM_CLASS;
  constructor() {
    this.T_ITEM_PICKING = new T_DN_ITEM_CLASS();
  }
}

export class DN {
  DNALL: any;
}

export class List_Group {
  SAMMG: string;
  ET_DN_ITEM: SmartItem;
  constructor() {
    this.ET_DN_ITEM = new SmartItem();
  }
}
export class ReverseItem {
  VBLEN: string;
}

export class CancelGoods {
  I_APP_NAME: string;
  I_INF_ID: string;
  I_INF_NAME: string;
  I_VBELN: string;
  I_LIFEX: string;
  I_OPTION: string;
  I_REVERSE_DATE: string;
  Items: any;
}

export class CancelModel {
  constructor() {
    this.list = new Array<CancelGoods>();
  }

  Distributions: string;
  DeliveryPacking: string;
  User: string;
  list: Array<CancelGoods>;
}

export class GIGR {
  Distributions: string;
  DeliveryPacking: string;
  User: string;
  list: Array<any>;
  constructor() {
    this.list = new Array<CancelGoods>();
  }
}

export class RETRUN_API_STATUS {
  status: string;
  massage: string;
}

export class GET_DN {
  Distributions: string;
  DeliveryPacking: string;
  DeliveryPacking_Flag: string;
  User: string;
  TypeGRGI: string;
  GetDeliveryResources_DN: GET_DELIVERY_IMPORT;
  GetDeliveryResources_Group: GET_PACKING_IMPORT;
  constructor() {
    this.GetDeliveryResources_DN = new GET_DELIVERY_IMPORT();
    this.GetDeliveryResources_Group = new GET_PACKING_IMPORT();
  }
}

export class IsBatch {
  isbatHeader: boolean;
  GroupNumber: number;
  Total: number;
  Tracking: number;
  Havebatch: boolean;
  BatchType: string;
  isDisable: boolean;
  canEdit: boolean;
}

export class SearchType {
  GRGISearchSettingID: number;
  ObjectID: string;
  DeliveryType: string;
  CreateBy: number;
  CreateDate: number;
  UpdateBy: number;
  UpdateDate: number;
}
export class Delivery {
  REVERS_CAR_LICENSE: string;
  REVERS_DRIVER_NAME: string;
  CAR_LICENSE: string;
  DRIVER_NAME: string;
  TDNAME: string;
  UserID: number
  Distributions: string;
  LFART: string;
  // ChangDelivery_1: Array<ChangDelivery>
  // ChangDelivery_2: Array<ChangDelivery>
  // constructor() {
  //   this.ChangDelivery_1 = new Array<ChangDelivery>();
  //   this.ChangDelivery_2 = new Array<ChangDelivery>();
  // }
}
export class SearchRequest {
  DELIVERY_NUMBER: string;
  SUPPLIER_NO: string;
  DN_DATE_FR: string;
  DN_DATE_TO: string;
  DN_STATUS: string;
  EXP_DATE_FR: string;
  EXP_DATE_TO: string;
  GI_DATE_FR: string;
  GI_DATE_TO: string;
  PO_DATE_FR: string;
  PO_DATE_TO: string;
  PO_NUMBER: string;
  CreateBy: number;
  }
export class GiGrRequest {
  PRINTER_ID: string;
  P_EBELN: string;
  P_EKORG: string;
  P_LIFNR: string;
  P_OPTION: string;
  P_PICK: string;
  P_VBELN: string;
  P_VBELV: string;
  CreatBy: string;

  ItemPicking: Array<ItemPicking>;
}
export class ItemPicking {
  VBELN: string;
  POSNR: string;
  EBELN: string;
  EBELP: string;
  MATNR: string;
  LFIMG: string;
  VRKME: string;
  WERKS: string;
  PLANT_DESC: string;
  EAN11: string;
  MAKTX_EN: string;
  MAKTX_TH: string;
  LOEKZ: string;
}
export class DisplayRequest {
  DELIVERY_NUMBER: string;
  SALE_ORG: string;
  SUPPLIER_NO: string;
  CreateBy: number;
}
export class ChangDelivery {
  SAVEMODE_DIRECT: string;
  HEADER: HEADER;
  LINES: INLINE;
}
export class HEADER {
  TDOBJECT: string;
  TDNAME: string;
  TDID: string;
  TDSPRAS: string;
}
export class INLINE {
  item: Array<ItemText>
}
export class ItemText {
  TDFORMAT: string
  TDLINE: string
}
export class REVERSERequest {
  P_OPTION : string;
  P_VBELN : string;
  VBELN : string;
  POSNR : string;
  EBELN : string;
  EBELP : string;
  MATNR : string;
  LFIMG : string;
  VRKME : string;
  WERKS : string;
  EAN11: string;
  CreateBy: number;

}
