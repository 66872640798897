import { ReturnApi } from './../model/Common.model';
import {
  PoImportSetting, Piacceptsave, POItems, StorageLocation, ParamSendMail, Pimanagesavebefor, PurGroupSearch, PurGroup, POApproveFlow,
  ListPiManage, SendRequestEditPOReq, SaveConfirmRequestEditPOReq, SendRequestEditPORejectReq
} from './../model/Purchaseorder.model';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { DROPDOWNLIST } from '../model/Purchaseorder.model';
import { POApprover } from '../model/setting.model';
import { SOAttachmentRequest, SOAttachmentResponse } from '../model/SOAttachment.model';
import { AnyAaaaRecord } from 'dns';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  })
};

const httpOptionsSap = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
    Authorization: 'Basic ' + btoa('PICBMD03:Picbmd-03'),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  })
};
let BASEURL = '';
@Injectable()
export class PIService {
  private Url = ''; // Url to web api
  currentUser: any;
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;
  }
  getpoapproveflowsupmit(data): Observable<Array<POApproveFlow>> {
    return this.http.post<Array<POApproveFlow>>(BASEURL + 'api/pi/getpoapproveflowsupmit/', data);
  }

  UpdatePrCpoApprover(Data: any) {
    return this.http.post<any>(BASEURL + 'api/pi/UpdatePrCpoApprover', Data);
  }
  getapprovepositionlist(POHeaderID: number, CpoPosition: string) {
    return this.http.post<any>(BASEURL + 'api/pi/getapprovepositionlist', { rpoheaderid: POHeaderID, rposition: CpoPosition });
  }
  Getpolist(value: any): Observable<any> {
    // const as = Object.assign({ pstatus: 0 });
    return this.http.post<any>(BASEURL + 'api/pi/Getpolist', value);
  }
  GetPOListMyRequest(value: any): Observable<any> {
    // const as = Object.assign({ pstatus: 0 });
    return this.http.post<any>(BASEURL + 'api/pi/GetPOListMyRequest', value);
  }
  GetRequestWithdrawPolist(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/GetRequestWithdrawPolist', value);
  }
  GetDLL_PILIST(): Observable<any> {
    return this.http.get<DROPDOWNLIST[]>(BASEURL + 'api/pi/GetDLL_PILIST');
  }
  SavePIApproveSetting(list: any): Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/pi/SavePIApproveSetting', list, httpOptionsSap);
  }
  MutiUpdateStatusPI(list): Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/pi/MutiUpdateStatusPI', list, httpOptionsSap);
  }
  UpdateStatusInitialPI(list): Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/pi/UpdateStatusInitialPI', list, httpOptionsSap);
  }
  GetPoImportList(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/Poimport');
  }
  GetPOApproverListByProductTypeid(producttypeid): Observable<Array<POApprover>> {
    return this.http.get<Array<POApprover>>(BASEURL + 'api/pi' + '/getpoapproverlistbyproducttypeid/'
      + producttypeid, httpOptions).pipe(tap(response => { }));
  }
  SavePoImport(header: PoImportSetting[]): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi' + '/SaveData', header, httpOptions).pipe(tap((response) => { }));
  }
  GetPOViewByID(value): Observable<ReturnApi> {
    return this.http.get<ReturnApi>(BASEURL + 'api/pi' + '/GetPOViewByID/' + value, httpOptions).pipe(tap((response) => { }));
  }
  AddpobyMerge(value: any) {
    return this.http.post<any>(BASEURL + 'api/pi' + '/AddpobyMerge', value, httpOptions);
  }

  GetDLL_PILIST_Manage(): Observable<any> {
    return this.http.get<DROPDOWNLIST[]>(BASEURL + 'api/pi/DLL_PILIST_Manage');
  }

  getValueDll(value: string): Observable<any> {
    return this.http.get<DROPDOWNLIST[]>(BASEURL + 'api/pi/Getamgtype/' + value);
  }

  getPoproductlistinforecord(value: any): Observable<any> {
    return this.http.post<POItems[]>(BASEURL + 'api/pi/getPoproductlistinforecord/', value);
  }

  Getpostoragelocation(value: number): Observable<any> {
    return this.http.get<StorageLocation[]>(BASEURL + 'api/pi/Getpostoragelocation/' + value);
  }

  PIManage(value: any): Observable<any> {
    console.log('value Send API', value);
    return this.http.post<any[]>(BASEURL + 'api/pi/PIManage', value);
  }

  GetCompanayProfile(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/GetCompanayProfile');
  }

  Getpoaddresses(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/Getpoaddresses', value);
  }

  Addandupdatepoaddresses(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/Addandupdatepoaddresses', value);
  }

  CancelPI(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/CancelPI', value);
  }
  DeletePI(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/DeletePI', value);
  }

  GetApproveList(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/GetApproveList', value);
  }

  GetUntiByMatVen(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/GetUntiByMatVen', value);
  }

  GetPRDoctypeDLL(value: any): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/GetPRDoctypeDLL/' + value);
  }

  getLoghistory(value: any): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/GetLoghistory/' + value);
  }
  GetHistoryBookingLog(value: any): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/pi/GetHistoryBookingLog/' + value);
  }
  Uploadfile(FormDatas: any) {
    const httpOptions = {
      headers: new HttpHeaders(
        {
          // 'Content-Type': 'application/json',
          // 'Authorization': (this.currentUser && this.currentUser.token) ? 'Bearer ' + this.currentUser.token : ''
        }
      )
    };
    const options = { content: 'formData' };
    return this.http.post<any>(BASEURL + 'api/pi/Upload', FormDatas, httpOptions);
  }

  Downloadfile(value: any): Observable<Blob> {
    return this.http.post(BASEURL + 'api/pi/Download', value, { headers: httpOptions.headers, responseType: 'blob' });
  }

  UpdateAttachment(value: any): Observable<any> {
    return this.http.post(BASEURL + 'api/pi/UpdateAttachment', value);
  }
  UpdateAttachmentSendMail(value: any): Observable<any> {
    return this.http.post(BASEURL + 'api/pi/UpdateAttachmentSendMail', value);
  }

  Getpobyid(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/Getpobyid/' + id);
  }

  GetPOApprovebyid(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/POApprovebyid/' + id);
  }

  GetPOViewByid(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetPoView/' + id);
  }
  GetApproveLevel(id: any): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetApproveLevel/' + id);
  }
  GetPurGroup(SearchParam?: PurGroupSearch): Observable<any> {
    // console.log("SearchParam",SearchParam);
    return this.http.post<any[]>(BASEURL + 'api/pi/PurGroup/', SearchParam);
  }
  ManageDataPurGroup(Data: PurGroup[]): Observable<any> { //Save Edit Delete
    console.log("Data", Data);

    return this.http.post<any[]>(BASEURL + 'api/pi/ManagePurGroup/', Data);
  }
  GetPIAppovalSettingList(DeptCode: string): Observable<any> {
    console.log("DeptCode", DeptCode);
    return this.http.get<any[]>(BASEURL + 'api/pi/PIAppovalSettingList/' + DeptCode);
  }

  SavePIApproveSetting1(list: any): Observable<ReturnApi> {
    return this.http.post<ReturnApi>(BASEURL + 'api/pi/SavePIApproveSetting1', list, httpOptionsSap);
  }

  GetPOReport(poid: number, currentUser: number): Observable<any> {
    console.log('aaaaa', poid, currentUser)
    return this.http.post(BASEURL + 'api/pi/GetPOReport', { ppoheaderid: poid, pprintby: currentUser }, { responseType: 'blob' })
  }
  GetPOReportSoap(poid: number, currentUser: number): Observable<any> {
    console.log('aaaaa', poid, currentUser)
    return this.http.post(BASEURL + 'api/pi/GetPOReportSoap', { ppoheaderid: poid, pprintby: currentUser }, { responseType: 'blob' })
  }

  SaveMerchandiserSetting(param) {
    return this.http.post<any>(BASEURL + 'api/pi/SaveMerchandiserSetting', param);
  }

  SupplierChangePoitem(param: any): Observable<any> {
    console.log('param', param);
    return this.http.post<any[]>(BASEURL + 'api/pi/SupplierChangePoitem', param);
  }

  Getckuserpoapprove(param: any) {
    return this.http.post<any>(BASEURL + 'api/pi/Getckuserpoapprove', param).pipe(tap((response) => { }));
  }
  GetSupplierpolist(value: any): Observable<any> {
    // const as = Object.assign({ pstatus: 0 });
    return this.http.post<any>(BASEURL + 'api/pi/GetSupplierpolist', value);
  }

  GetSupplierpolistConfirm(value: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/pi/GetSupplierpolistConfirm', value);
  }

  GetConFirmDelivery(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetConFirmDelivery/' + id);
  }
  Getcurrency(): Observable<any> {
    // const as = Object.assign({ pstatus: 0 });
    return this.http.get<any>(BASEURL + 'api/pi/Getcurrency');
  }

  GetPayment(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetPayment/' + id);
  }
  Getinforecordpolist(param: any): Observable<any> {
    return this.http.post<any[]>(BASEURL + 'api/pi/Getinforecordpolist/', param);
  }


  ManageConFirmDelivery(Data: any[]): Observable<any> { //Save Edit
    console.log("Data", Data);
    return this.http.post<any[]>(BASEURL + 'api/pi/ManageConFirmDelivery/', Data);
  }

  SavePiManage(Data: ListPiManage): Observable<any> { //Save Edit

    return this.http.post<any[]>(BASEURL + 'api/pi/SavePiManage/', Data);
  }

  SendmailPOsupplier(Data: ParamSendMail): Observable<any> {

    return this.http.post<any[]>(BASEURL + 'api/pi/SendmailPOsupplier/', Data);
  }


  SavePiManagebeforattach(Data: Pimanagesavebefor): Observable<any> { //Save Edit

    return this.http.post<any[]>(BASEURL + 'api/pi/SavePiManagebeforattach/', Data);
  }
  GetPoDetailheader(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetPoDetailheader/' + id);
  }
  GetPoDetailitem(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetPoDetailitem/' + id);
  }
  GetPoDetailattach(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetPoDetailattach/' + id);
  }
  GetRequestEditPO(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetRequestEditPO/' + id);
  }
  GetRequestEditPOByPONumber(id: string): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetRequestEditPOByPONumber/' + id);
  }
  GetStatusSupplier(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetStatusSupplier/' + id);
  }
  SendRequestEditPO(Data: SendRequestEditPOReq): Observable<any> {
    console.log(Data);
    return this.http.post<any[]>(BASEURL + 'api/PI/SendRequestEditPO', Data);
  }
  SendRequestEditPOFromReject(Data: SendRequestEditPORejectReq): Observable<any> {
    console.log(Data);
    return this.http.post<any[]>(BASEURL + 'api/PI/SendRequestEditPOFromReject', Data);
  }
  SaveConfirmRequestEditPO(Data: SaveConfirmRequestEditPOReq): Observable<any> {
    console.log(Data);
    return this.http.post<any[]>(BASEURL + 'api/PI/SaveConfirmRequestEditPO', Data);
  }
  Getpiacceptandpiapprove(id: number): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/Getpiacceptandpiapprove/' + id);
  }
  Getsupplierpolistinforecord(param: any): Observable<any> {
    return this.http.post<any[]>(BASEURL + 'api/pi/Getsupplierpolistinforecord/', param);
  }
  SavePiAccept(Data: Piacceptsave): Observable<any> {

    return this.http.post<any[]>(BASEURL + 'api/pi/SavePiAccept/', Data);
  }
  SavePiApprove(Data: Piacceptsave): Observable<any> {

    return this.http.post<any[]>(BASEURL + 'api/pi/SavePiApprove/', Data);
  }

  gettaxidbytaxcode(taxcode: string): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/gettaxidbytaxcode/' + taxcode);
  }
  GetConFirmDeliverySOAP(pponumber: string): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetConFirmDeliverySOAP/' + pponumber);
  }
  GetPOLastGRByPoNumber(pponumber: string): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetPOLastGRByPoNumber/' + pponumber);
  }

  ManageConFirmDeliveryDSSC(Data: any[]): Observable<any> { //Save Edit
    console.log("Data", Data);
    return this.http.post<any[]>(BASEURL + 'api/pi/ManageConFirmDeliveryDSSC/', Data);
  }
  gettaxlist(): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetTaxList');
  }
  GetSupplierList(SupplierCode: any): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetSupplierList/' + SupplierCode);
  }

  GetDCTypebyPLANT(PLANT: string): Observable<any> {
    return this.http.get<any[]>(BASEURL + 'api/pi/GetDCTypebyPLANT/' + PLANT);
  }

  GetMerchandiserByUserId(id): Observable<any> {
    // const as = Object.assign({ pstatus: 0 });
    return this.http.get<any>(BASEURL + `api/pi/GetMerchandiserByUserId/${id}`);
  }

  RequestWithdraw(param: any): Observable<any> {
    return this.http.post<any[]>(BASEURL + `api/pi/RequestWithdraw/`, param);
  }

  SubmitRequestWithdraw(param: any): Observable<any> {
    return this.http.post<any[]>(BASEURL + `api/pi/SubmitWithdraw/`, param);
  }

  GetCountRequestWithdraw(userId: number): Observable<any> {
    return this.http.get<any>(BASEURL + `api/pi/GetCountRequestWithdraw/${userId}`);
  }

  DecryptRequestWithdrawToken(param: any): Observable<any> {
    return this.http.post<any>(BASEURL + `api/pi/DecryptRequestWithdrawToken/`, param);
  }

  GetSOAttachMent(soNumber: string, isVendorFlag: boolean): Observable<any> {
    let queryParams = new HttpParams()
      .set('SoNumber', soNumber)
      .set('IsVendorFlag', isVendorFlag ? '1' : '0');
    return this.http.get<any>(BASEURL + 'api/pi/GetEORSoAttachment', { params: queryParams });
  }

  GetSOAttachMentBySAPPODoc(poDoc: string, isVendorFlag: boolean): Observable<any> {
    let queryParams = new HttpParams()
      .set('PoDoc', poDoc)
      .set('IsVendorFlag', isVendorFlag ? '1' : '0');
    return this.http.get<any>(BASEURL + 'api/pi/GetEORSoAttachmentByPODoc', { params: queryParams });
  }

  UpdateSOAttachment(FormDatas: any): Observable<any> {
    return this.http.post(BASEURL + 'api/pi/UpdateSOAttachment', FormDatas);
  }

  DownloadSOAttachMent(pathFile: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.post<any>(BASEURL + 'api/InboundEOrderingInterface/DownloadSOAttachFile/', { pathFile: pathFile }, httpOptions);
  }
}
