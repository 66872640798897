import { AgmAttachment, Status } from './../model/setting.model';
import { ReturnApi } from './../model/Common.model';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalService } from './modal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AgmSelectedTab, AgreementList_Search, AgreementList, AgmHeader, UpdateStatusAgreement, AgmAprroval, ApprovAgmModel } from '../model/agreement.model';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { MasterSearch, GetSupplierList, GetSupplierBySupplierId, UpdateSupplier } from '../model/master.model';


// const httpOptions = {
//     headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
//       'Access-Control-Allow-Origin': '*',
//       'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
//       'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
//     })
//   };
const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};
const API_Url = environment;
let BASEURL = '';
@Injectable()
export class MasterService {



  constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;

  }

  DecryptToken(param: any): Observable<any> {
    return this.http.post<any>(BASEURL + `api/master/DecryptToken/`, param);
  }
  
  getcategorymapping() {
    return this.http.get<any>(BASEURL + 'api/master/getcategorymapping/', httpOptions).pipe(tap(response => { }));
  }
  getperiodlist() {
    return this.http.get<any>(BASEURL + 'api/master/getperiodlist/', httpOptions).pipe(tap(response => { }));
  }
  GetBenefitRequestStatusList(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/master/GetBenefitRequestStatus', httpOptions).pipe(tap(response => { }));
  }
  
  GetStatusByTransactionType(id: number): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/master/GetStatusByTransactionType/'+id, httpOptions).pipe(tap(response => { }));
  }

  getplantdcandrdcandother() {
    return this.http.get<any>(BASEURL + 'api/master/getplantdcandrdcandother/', httpOptions).pipe(tap(response => { }));
  }
  getplant() {
    return this.http.get<any>(BASEURL + 'api/master/getplant/', httpOptions).pipe(tap(response => { }));
  }
  getdata4BookingTable() {
    return this.http.get<any>(BASEURL + 'api/master/getdata4BookingTable/', httpOptions).pipe(tap(response => { }));
  }
  GetDCGate(plantcode: any) {
    return this.http.get<any>(BASEURL + 'api/master/getDCGate/' + plantcode, httpOptions).pipe(tap(response => { }));
  }

  GetPlantDC(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/master/getplantdc', httpOptions).pipe(tap(response => { }));
  }
  GetTimeOfBooking(): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/master/gettimeofbooking', httpOptions).pipe(tap(response => { }));
  }
  Getagmlist(): Observable<any> {
    console.log('Send To API getMasterList');
    return this.http.post<any>(BASEURL + 'api/master/getMasterList', httpOptions).pipe(tap(response => { }));
  }
  GetSupplierlistSearch(Param: MasterSearch): Observable<Array<GetSupplierList>> {
    console.log('Send To API GetSupplierList',);
    return this.http.post<Array<GetSupplierList>>(BASEURL + 'api/master/getMasterList', Param, httpOptions).pipe(tap(response => { }));
  }
  GetMasterPiList(Param: MasterSearch): Observable<Array<GetSupplierList>> {
    console.log('Send To API GetSupplierList',);
    return this.http.post<Array<GetSupplierList>>(BASEURL + 'api/master/getMasterPiList', Param, httpOptions).pipe(tap(response => { }));
  }

  getdcholiday(Param): Observable<Array<any>> {
    return this.http.post<Array<GetSupplierList>>(BASEURL + 'api/master/getdcholiday', Param, httpOptions).pipe(tap(response => { }));
  }
  getuserforsupplier(suppliercode): Observable<Array<GetSupplierList>> {
    // console.log('Send To API GetSupplierListMenu',);
    return this.http.get<Array<GetSupplierList>>(BASEURL + 'api/master/getuserforsupplier/' + suppliercode, httpOptions).pipe(tap(response => { }));
  }

  getmenuconnectanothersystem(): Observable<any> {
    // console.log('Send To API GetSupplierList',);
    return this.http.get<any>(BASEURL + 'api/master/getmenuconnectanothersystem/', httpOptions).pipe(tap(response => { }));
  }

  GetSupplierBySupplierId(SupplierId: number): Observable<GetSupplierBySupplierId> {
    return this.http.get<GetSupplierBySupplierId>(BASEURL + 'api/master/' + 'getSupplier/' + SupplierId, httpOptions).pipe(tap(response => { }));
  }

  UpdateSupplier(param: UpdateSupplier) {
    return this.http.post<any>(BASEURL + 'api/master' + '/UpdateSupplier', param, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }

  updatedc(param: any): Observable<ReturnApi> {
    return this.http.post<any>(BASEURL + 'api/master' + '/updatedc', param, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }

  GetStatus(transactionType): Observable<Array<Status>> { //contract
    return this.http.get<Array<Status>>(BASEURL + 'api/setting' + '/GetStatus/' + transactionType, httpOptions).pipe(tap(response => { }));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logA(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ScgCompanyService message with the MessageService */
  private logA(message: string) {
    console.log(message);
    // this.messageService.add('ScgCompanyService: ' + message);
  }
}

