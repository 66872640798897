import { ReturnApi } from './../model/Common.model';
import { Injectable, Inject, Compiler } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { environment, ADFS } from "../../environments/environment";
import { UserLogin, User } from "../model/user.model";
import { MenuConnectAnotherSystem } from '../model/master.model';


const API_URL = environment.R3_GI_Reverse;
const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
        }
    )
};
let BASEURL = '';
@Injectable()
export class AuthenService {
    private Url = API_URL + '/authen';  // URL to web api

    currentLanguage: any;
    constructor(private _compiler: Compiler,private http: HttpClient,  @Inject('BASE_URL') private baseUrl: string) {
        BASEURL = baseUrl;
    }
    GetToken(code: string) {
        const body = `grant_type=authorization_code&client_id=${ADFS.clientId}&redirect_uri=${ADFS.redirectUri}&code=${code}`;
        return this.http.post<any>(`https://scg-cors.herokuapp.com/${ADFS.tokenEndpoint}`, body)
    }
    login(authenuser: UserLogin): Observable<ReturnApi> {
     
        return this.http.post<ReturnApi>(BASEURL + 'api/authentication/login', authenuser, httpOptions).pipe(
            tap((user: any) => {
                // this.log(`login w/ userLogin=${JSON.stringify(user)}`);
                // window.localStorage.setItem('currentUser', JSON.stringify(user));
            }),
            catchError(this.handleError<UserLogin>('Login Err'))
        );
    }
    logout() {
        // console.log("this.jwt()", this.jwt())
        // return this.http.post(this.Url + '/logout', null, this.jwt())
        //     .pipe(
        //         tap((response: any) => {
        //             window.localStorage.removeItem('currentUser');

        //             this.log(`Logout`);
        //         }),
        //         catchError(this.handleError('Logout', []))
        //     );
        window.localStorage.removeItem('currentUser');
         this._compiler.clearCache();
        location.href = ADFS.logoutUrl;
    }


    verify(): Observable<boolean> {
        return  this.jwt() ? this.http.post<ReturnApi>(BASEURL + 'api/authentication/verify', null, this.jwt())
            .pipe(
                tap((response: ReturnApi) => {this.log(`verify`);console.log(response);
                 }),
                map(
                    res => res.Success?res.Data:false,
                    error => false
                  )
                ) : of(false)
            
    }


    ExternalVerify(menu: MenuConnectAnotherSystem): Observable<any> {
        return  this.jwt() ? this.http.post<ReturnApi>(BASEURL + 'api/authentication/externalverify', menu, this.jwt())
            .pipe(
                tap((response: ReturnApi) => {}),
                ) : of(false)
            
    }


    getIpAddress() {
        return this.http
            .get('https://jsonip.com')
            .pipe(
                tap((response: any) => this.log(`GetIPAddress`)),
                catchError(this.handleError('IPAddress', []))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
        // console.log(message);
        // this.messageService.add('JobSafetySettingService: ' + message);
    }

 
    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            // let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + currentUser.token });
            let hOptions = {
                headers: new HttpHeaders(
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + currentUser.token
                    }
                )
            };
            return hOptions;
        }
    }
    errorHandl(error: { error: { message: string; }; status: any; message: any; }) {
        return throwError(error);
      }
}
