import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  Customer,
  CustomerGroup,
  CustomerForDD
} from '../model/customer.model';
import { User } from '../model/user.model';

const API_URL = environment;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class CustomerService {
  private Url = ''; // URL to web api
  currentUser: User;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log(this.currentUser);
    this.Url = API_URL + '/' + '1' + '/customer';
  }

  getCustomers(): Observable<any> {
    return this.http.get<any>(this.Url, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  GetCustomerForExport(type: number): Observable<any> {
    return this.http
      .post(this.Url + '/GetCustomerForExport/' + type, null, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'arraybuffer'
      })
      .pipe(
        tap(x => { }),
        catchError(this.handleError<{}>('SearchWorkPermit'))
      );
  }

  getCustomerById(id: number): Observable<Customer> {
    return this.http
      .get<Customer>(
        this.Url + '/GetCustomerByID/' + id.toString(),
        httpOptions
      )
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }

  getCustomerGroups(): Observable<CustomerGroup[]> {
    return this.http
      .get<CustomerGroup[]>(this.Url + '/group', httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }

  getCustomerddl(): Observable<any> {
    return this.http.get<any>(this.Url).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  getCustomerGroupById(id: number): Observable<CustomerGroup> {
    return this.http
      .get<CustomerGroup>(this.Url + '/group/' + id.toString(), httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }

  AddCustomer(data: Customer): Observable<any> {
    return this.http.post<any>(this.Url + '', data, httpOptions).pipe(
      tap(response => {
        // this.log(`fetched `)
      })
    );
  }

  UpdateCustomer(id: number, data: Customer): Observable<any> {
    return this.http
      .put<any>(this.Url + '/' + id.toString(), data, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ScgCompanyService message with the MessageService */
  private log(message: string) {
    console.log(message);
    // this.messageService.add('ScgCompanyService: ' + message);
  }
}
