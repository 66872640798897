import { Vendor, VendorAddress } from '../model/vendor.model';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from "../../environments/environment";


const API_URL = environment.R3_GI_Reverse;
const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
        }
    )
};

@Injectable()
export class VendorService {
    private Url = '';  // URL to web api
    currentUser: any;

    constructor(
        private http: HttpClient) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.Url = API_URL + '/' + ( "1") + '/supplier';

        // console.log(this.currentUser);
    }

    getVendors(): Observable<Vendor[]> {
        return this.http.get<Vendor[]>(this.Url, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }

    GetVendorForExport(type: number): Observable<any> {

        return this.http.post(this.Url + '/GetVendorForExport/' + type, null, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',

            }),
            responseType: 'arraybuffer'
        }).pipe(tap((x) => {
        }),
            catchError(this.handleError<{}>('SearchWorkPermit'))
        );
    } 

    getVendorAddress(id: number): Observable<VendorAddress[]> {
        return this.http.get<VendorAddress[]>(this.Url + '/supplieraddress' + id.toString(), httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }

    GetVendorByID(id: number): Observable<Vendor> {
        return this.http.get<Vendor>(this.Url + '/' + id.toString(), httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }

    AddVendor(data: Vendor): Observable<any> {
        return this.http.post<any>(this.Url + "", data, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }

    UpdateVendor(vendorId: number, data: Vendor): Observable<any> {
        return this.http.put<any>(this.Url + '/' + vendorId.toString(), data, httpOptions)
            .pipe(
                tap(response => {
                    // this.log(`fetched `)
                })
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a ScgCompanyService message with the MessageService */
    private log(message: string) {
        console.log(message);
        // this.messageService.add('ScgCompanyService: ' + message);
    }


}