import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AcceptChangeSORequest, AcceptChangeSOResponse, CheckSoTokenRequest, CheckSoTokenResponse, RejectChangeSORequest, RejectChangeSOResponse } from "../model/approvechangeso.model";
import { ModalService } from "./modal.service";


const httpOptions = {
    headers: new HttpHeaders(
        {
            'Content-Type': 'application/json',
        }
    )
};
const API_Url = environment;
let BASEURL = '';
@Injectable()
export class ApproveChangeSOService {
    constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
        BASEURL = baseUrl;
    }

    CheckSoToken(param: CheckSoTokenRequest): Observable<CheckSoTokenResponse> {
        return this.http.post<CheckSoTokenResponse>(BASEURL + 'api/ApproveChangeSO/CheckSoToken', param, httpOptions).pipe(tap(response => { }));
    }

    AcceptChangeSO(param: AcceptChangeSORequest): Observable<AcceptChangeSOResponse>{
        return this.http.post<AcceptChangeSOResponse>(BASEURL + 'api/ApproveChangeSO/AcceptChangeSO', param, httpOptions).pipe(tap(response => { }));
    }

    RejectChangeSO(param: RejectChangeSORequest): Observable<RejectChangeSOResponse>{
        return this.http.post<RejectChangeSOResponse>(BASEURL + 'api/ApproveChangeSO/RejectChangeSO', param, httpOptions).pipe(tap(response => { }));
    }

}