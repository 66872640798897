import { Injectable } from '@angular/core';

@Injectable()

export class SavefileService {

  csvExport(response, name: string) {

    console.log('response');
    const file = new Blob([response], { type: 'text/csv' });
    const url = window.URL.createObjectURL(file); // <-- work with blob directly

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    const today = Date.now();
    const date = today;
    const filename = name + date;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // window.navigator.msSaveOrOpenBlob(file, 'WorkPermit_' + model.workNo + '.pdf');
      window.navigator.msSaveOrOpenBlob(file, filename + '.csv');
      console.log('Yes');
    } else {
      a.href = url;
      a.download = filename + '.csv';
      a.click();
      console.log('NO')
    }

  }

  excelExport(response, name: string) {
    console.log('response');
    const file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(file); // <-- work with blob directly

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    const today = Date.now();
    const date = today;
    const filename = name + date;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // window.navigator.msSaveOrOpenBlob(file, 'WorkPermit_' + model.workNo + '.pdf');
      window.navigator.msSaveOrOpenBlob(file, filename + '.xlsx');
      console.log('Yes');
    } else {
      a.href = url;
      a.download = filename;
      a.click();
      console.log('NO')
    }

  }
}
