import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { GET_DELIVERY_EXPORT, GET_GROUP_DN_EXPORT, GET_DELIVERY_IMPORT, LogGetDelivery, T_DN_HEADER_CLASS, CancelGoods, CancelModel, GET_DN, GET_PACKING_IMPORT, ChangDelivery, Delivery, SearchRequest, DisplayRequest, GiGrRequest, REVERSERequest } from '../model/GoodsIssue.model';
import { T_RETURN_CLASS, GI_T_RETURN_CLASS } from '../model/Common.model';
import { Observable } from 'rxjs/Observable';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { ModalService } from './modal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
    'Authorization': 'Basic ' + btoa('PICBMD03:Picbmd-03'),
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  })
};

const API_Url = environment;
let BASEURL = '';

@Injectable()
export class GoodIssueService {

  constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;
  }

  T_RETURN_CLASSs = new T_RETURN_CLASS();
  T_RETURN_CLASS_Entrys = new T_RETURN_CLASS();

  getJSON(): Observable<any> {
    return this.http.get<GET_DELIVERY_EXPORT>('../../assets/data/item.json');
  }
  GetGroupJSON(): Observable<any> {
    return this.http.get<GET_DELIVERY_EXPORT>('../../assets/data/Get_Group_DN.json');
  }

  Get_Delivery(input: GET_DELIVERY_IMPORT, LogGetDeliverys: LogGetDelivery, Distributions: any, Delivery: any, Type: string): Observable<GET_DELIVERY_EXPORT> {
    const value = new GET_DN();
    value.Distributions = Distributions;
    value.User = LogGetDeliverys.CreateBy;
    value.GetDeliveryResources_DN = input;
    value.DeliveryPacking = '1';

    if (Distributions == '1') {
      LogGetDeliverys.APIName = 'DNGetList';
      LogGetDeliverys.ServerName = 'S4';
      if (Delivery == '2') {
        value.DeliveryPacking_Flag = 'OK';
      }
      value.TypeGRGI = Type;
    }

    if (Distributions == '2') {
      LogGetDeliverys.APIName = 'R3DNGetList';
      LogGetDeliverys.ServerName = 'R3';
      value.DeliveryPacking_Flag = '';
    }

    if (Distributions == '3') {
      LogGetDeliverys.APIName = 'DNGetList';
      LogGetDeliverys.ServerName = 'S4';
      value.DeliveryPacking_Flag = '';
    }
    return this.http.post<GET_DELIVERY_EXPORT>(BASEURL + 'api/scg' + '/Getdelivery', value, httpOptions);
  }

  // GROUP
  Get_Group_DN(arg0: GET_PACKING_IMPORT, LogGetDeliverys: LogGetDelivery, Distributions: any): Observable<GET_GROUP_DN_EXPORT> {
    const value = new GET_DN();
    value.DeliveryPacking = '2';
    value.Distributions = Distributions;
    value.GetDeliveryResources_Group = arg0;
    return this.http.post<GET_GROUP_DN_EXPORT>(BASEURL + 'api/scg' + '/Getdelivery', value, httpOptions).pipe(
      tap(
        response => {
          console.log('Send To API', value);
          console.log('Recive From API', response);

          LogGetDeliverys.APIName = 'DNGetGroup';
          LogGetDeliverys.ServerName = 'R3';
          LogGetDeliverys.ConnectStatus = '1';
          LogGetDeliverys.Message = '';
          const obj = {
            LogGetDelivery: LogGetDeliverys,
            T_RETURN: null
          };

          if (response.T_RETURN) {
            obj.T_RETURN = JSON.stringify(response.T_RETURN);
          }
          // Stanalone Add log Group !!
          this.http.post(BASEURL + 'api/scg' + '/LogGetDeliveryAndReturn', obj).subscribe(res => { });
        }
      )
    );
  }

  log_GIGR(value: any): Observable<any> {
    return this.http.post(BASEURL + 'api/scg' + '/LogGIGRI', value);
  }

  uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
      const random = (Math.random() * 16) | 0;
      const value = char === 'x' ? random : (random % 4) + 8;
      return value.toString(16);
    });
  }

  pdf(input: any, LogGetDeliverys: LogGetDelivery, Distributions: any, Delivery: any, Type: string) {
    const value = new GET_DN();
    value.Distributions = Distributions;
    value.User = LogGetDeliverys.CreateBy;
    if (Distributions == '1') {
      LogGetDeliverys.APIName = 'DNGetList';
      LogGetDeliverys.ServerName = 'S4';
      if (Delivery == '2') {
        value.DeliveryPacking_Flag = 'OK';
      }
      value.TypeGRGI = Type;
    }

    if (Delivery == '1') {
      value.GetDeliveryResources_DN = input;
      value.DeliveryPacking = '1';
    }
    if (Delivery == '2') {
      value.DeliveryPacking = '2';
      value.GetDeliveryResources_Group = input;
    }

    if (Distributions == '2') {
      LogGetDeliverys.APIName = 'R3DNGetList';
      LogGetDeliverys.ServerName = 'R3';
      value.DeliveryPacking_Flag = '';
    }
    return this.http.post(BASEURL + 'api/scg' + '/PDF', value, { headers: httpOptions.headers, responseType: 'blob' });
  }

  Getcountprint(obj: T_DN_HEADER_CLASS): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/scg' + '/LogNumber', obj);
  }

  CancelGoods(input: CancelModel): Observable<any> {
    // console.log('Send To API CancelGoods', input);
    return this.http.post<any>(BASEURL + 'api/scg' + '/CancelGoods', input).pipe(tap(response => { }));
  }

  ProcessingGIGR(value: any) {
    // console.log('Send To API GI', value);
    return this.http.post<any>(BASEURL + 'api/scg' + '/GIGR', value).pipe(tap(response => { }));
  }

  ProcessingCancal_return(value: any): Observable<any> {
    console.log('Send To API GI', value);
    return this.http.post<any>(BASEURL + 'api/scg' + '/Cancal_return', value, httpOptions);
  }

  SearchType(value: string): Observable<any> {
    return this.http.get<any>(BASEURL + 'api/scg' + '/SearchType/' + value, httpOptions);
  }

  GetChangGiGr(obj: Delivery): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/scg' + '/Chang', obj);
  }
  SI_SCGN_EPUR_SEARCH_POAsync(obj: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/scg' + '/SI_SCGN_EPUR_SEARCH_POAsync', obj);
  }
  SI_SCGN_EPUR_SEARC_DN_REQClient(obj: SearchRequest): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/scg' + '/SI_SCGN_EPUR_SEARC_DN_REQClient', obj);
  }

  SI_SCGN_EPUR_REVERSE_GRGI_DN_REQClient(obj: REVERSERequest): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/scg' + '/SI_SCGN_EPUR_REVERSE_GRGI_DN_REQClient', obj);
  }

  SI_SCGN_EPUR_DISPLAY_DN_REQRequest(obj: DisplayRequest): Observable<any> {
    console.log('SwdxwwI', obj);
    return this.http.post<any>(BASEURL + 'api/scg' + '/SI_SCGN_EPUR_DISPLAY_DN_REQRequest', obj);
  }
  pdfv2(input: any, LogGetDeliverys: LogGetDelivery, Distributions: any, Delivery: any, Type: string) {
    const value = new GET_DN();
    value.Distributions = Distributions;
    value.User = LogGetDeliverys.CreateBy;
    if (Distributions == '1') {
      LogGetDeliverys.APIName = 'DNGetList';
      LogGetDeliverys.ServerName = 'S4';
      if (Delivery == '2') {
        value.DeliveryPacking_Flag = 'OK';
      }
      value.TypeGRGI = Type;
    }

    if (Delivery == '1') {
      value.GetDeliveryResources_DN = input;
      value.DeliveryPacking = '1';
    }
    if (Delivery == '2') {
      value.DeliveryPacking = '2';
      value.GetDeliveryResources_Group = input;
    }

    if (Distributions == '2') {
      LogGetDeliverys.APIName = 'R3DNGetList';
      LogGetDeliverys.ServerName = 'R3';
      value.DeliveryPacking_Flag = '';
    }
    return this.http.post(BASEURL + 'api/scg' + '/PDFV2', value, { headers: httpOptions.headers, responseType: 'blob' });
  }

GetSEARCH_PO_REQ_Excel(param: any) {
     return this.http.post(BASEURL + 'api/scg/GetSEARCH_PO_REQ_Excel/', param, {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',

      }),
      responseType: 'arraybuffer'
    }).pipe(tap((x) => {
      console.log("x", x);
    }),
      tap((y) => {
        console.log("y", y);
        catchError(this.handleError<any>("GetBookingReportExcel"))
      }),
    );
  }
  SI_SCGN_EPUR_GRGI_DN_REQAsync(obj: GiGrRequest): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/scg' + '/SI_SCGN_EPUR_GRGI_DN_REQAsync', obj);
  }

  SI_SCGN_EPUR_DISPLAY_PO_REQAsync(obj: any,UserID: any): Observable<any> {
    return this.http.post<any>(BASEURL + 'api/scg' + '/SI_SCGN_EPUR_DISPLAY_PO_REQAsync/' + UserID, obj);
  }

      /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.logA(`${operation} failed: ${error.message}`);

          // Let the app keep running by returning an empty result.
          return of(result as T);
      };
  }
    /** Log a ScgCompanyService message with the MessageService */
    private logA(message: string) {
      console.log(message);
      // this.messageService.add('ScgCompanyService: ' + message);
  }
}
