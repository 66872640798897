import { ReturnApi } from './../model/Common.model';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ModalService } from './modal.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TemplateList_Search, TemplateList, UpdateContractTemplateStatus, TemplateAgreementList_Search, TemplateAgreementList, UpdateAgmTemplateStatus, Getcontracttemplate, ContractTemplate, AgmTempateAllData, AgmTemplate } from '../model/template.model';



const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa('PICBMD01:Cbmq-220'),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
    })
  };

  const API_Url = environment;
let BASEURL = '';
@Injectable()
export class TemplateService {
  
  
  
  

constructor(private http: HttpClient, private DatePipes: DatePipe, private msg: ModalService, private spinnerService: Ng4LoadingSpinnerService, @Inject('BASE_URL') private baseUrl: string) {
    BASEURL = baseUrl;

 }
 GetAgmTemplateByID(id: number) : Observable<AgmTemplate> {
  return this.http.get<AgmTemplate>(BASEURL + 'api/template' + '/GetAgmTemplateByID/'+id, httpOptions).pipe(tap(response => { }));
  }
  SaveTemplateAgreement(h: AgmTemplate): Observable<ReturnApi> {
   return this.http.post<ReturnApi>(BASEURL + 'api/template' + '/SaveTemplateAgreement',h, httpOptions).pipe(tap(response => { }));
   }
 GetAgmTempateAllData(): Observable<AgmTempateAllData> {
   return this.http.get<AgmTempateAllData>(BASEURL + 'api/template' + '/GetAgmTempateAllData', httpOptions).pipe(tap(response => { }));
   }
 GetTemplatelist(): Observable<any> {
  console.log('Send To API Getagmlist');
   return this.http.post<any>(BASEURL + 'api/template' + '/gettemplatelist', httpOptions).pipe(tap(response => { }));
   }
   GetRemplateSearch(Param: TemplateList_Search): Observable<Array<TemplateList>> {
   console.log('Send To API TemplateList', );
     return this.http.post<Array<TemplateList>>(BASEURL + 'api/template' + '/gettemplatelist',Param,  httpOptions).pipe(tap(response => { }));
   }
   updateDataFlag(param: UpdateContractTemplateStatus) {
    //update Contract Status to status cancel
    return this.http.put<any>(BASEURL + 'api/template' + '/updateStatus', param, httpOptions)
      .pipe(
        tap(response => {
          // this.log(`fetched `)
        })
      );
  }
  GetTemplateAgreementlist(): Observable<any> {
    console.log('Send To API TemplateAgreementList');
     return this.http.post<any>(BASEURL + 'api/template' + '/getagmtemplatelist', httpOptions).pipe(tap(response => { }));
     }
     GetRemplateAgreementSearch(Param: TemplateAgreementList_Search): Observable<Array<TemplateAgreementList>> {
     console.log('Send To API TemplateAgreementList', );
       return this.http.post<Array<TemplateAgreementList>>(BASEURL + 'api/template' + '/getagmtemplatelist',Param,  httpOptions).pipe(tap(response => { }));
     }
     UpdateAgmTemplateStatus(param: UpdateAgmTemplateStatus) {
      //update Contract Status to status cancel
      return this.http.put<any>(BASEURL + 'api/template' + '/UpdateAgmtemplateStatus', param, httpOptions)
        .pipe(
          tap(response => {
            // this.log(`fetched `)
          })
        );
    }
    
    getTemplatebyID(ContractTemplateID: number) {
  console.log('Send To API Getcontracttemplatebyid', );
    return this.http.post<any>(BASEURL + 'api/template' + '/Getcontracttemplatebyid/'+ ContractTemplateID,  httpOptions);
  }
   
  GetContractTemplateDetail(ContractTemplateID: number) {
    console.log('Send To API GetContractTemplateDetail', );
      return this.http.post<any>(BASEURL + 'api/template' + '/GetContractTemplateDetail/'+ ContractTemplateID,  httpOptions);
    }
  
    Save(header:ContractTemplate):Observable<any> { //ReturnApi
      console.log("header",header);
      return this.http.post<any>(BASEURL + 'api/template' + '/SaveData', header,  httpOptions).pipe(tap(response => { }));
    }
    getTemplateCode() {
        return this.http.get<any>(BASEURL + 'api/template' + '/GetContractTempateCode',  httpOptions);
      }
}
