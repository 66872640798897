import { GetUserPermissionPCResult } from './../model/user.model';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IOption } from 'ng-select';
import { User } from '../model/user.model';

@Injectable()
export class PermissionService {

    private permissionList: Array<GetUserPermissionPCResult>;
    private currentUser: User = new User();

    constructor() {
        this.permissionList = new Array<GetUserPermissionPCResult>();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentUser) {
            this.permissionList = new Array<GetUserPermissionPCResult>();
            this.permissionList = this.currentUser.Permission;
            console.log('permissionList', this.permissionList);
        }
    }

    public CheckPermission = (TYPE: any, ACTION: number): boolean => {
        let res: boolean = false;
        if (!TYPE) {
            return true;
        }

        if (this.permissionList) {
            let filterByType = this.permissionList.filter(x => x.ObjID == TYPE && x.PermissionID == ACTION);
            // console.log(filterByType);
            if (filterByType.length > 0) {
                // console.log(filterByType[0].ObjName, filterByType[0].Permissionflag);
                // console.log(filterByType[0].Permissionflag, filterByType[0].ObjID);
                return filterByType[0].Permissionflag;
            }
        } else {
            return res;
        }
        //return true;
    }

    public CheckCanAccessURL = (url: string): boolean => {
        let res: boolean = false;
        // console.log("!!!");
        if (this.permissionList) {
            let checkUrl = this.permissionList.filter(x => x.Description == url && x.PermissionID == 100);
            if (checkUrl.length > 0) {
                res = checkUrl[0].Permissionflag;
                return res;
            } else {
                //not fond url
                // console.log("not fond url");
                return true;
            }
        } else {
            return res;
        }
        return true;
    }
}

